const LOG_LEVEL = import.meta.env.VITE_LOG_LEVEL

// TODO: Maybe replace with sessionStorage?
window.Sanitas = window.Sanitas || {}
window.Sanitas.DVP = window.Sanitas.DVP || {}
window.Sanitas.DVP.log_level = LOG_LEVEL

const SEVERITY = {
  ERROR: 'error',
  WARNING: 'warn',
  INFO: 'info',
}

function getLogLevel() {
  return window.Sanitas.DVP.log_level
}

function log(severity, params) {
  const level = getLogLevel()
  if (!level || level === '') return
  if (!level.includes(severity.toUpperCase())) return

  if (Array.isArray(params)) {
    console[severity](...params)
  } else {
    console[severity](params)
  }
}

export function logError(params) {
  log(SEVERITY.ERROR, params)
}

export function logInfo(params) {
  log(SEVERITY.INFO, params)
}

export function logWarning(params) {
  log(SEVERITY.WARNING, params)
}
