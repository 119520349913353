import basketStore from '@/store/basket'
import usePersonDetails from '@/hooks/usePersonDetails'

import { DateTimeUtil } from '@/utils/DateTime'

export default function useCancellation() {
  // HOOKS
  const { getPersonsByMutability } = usePersonDetails()

  // METHODS
  function createPreInsurerVvg(personId, cancellationExisting = false) {
    const personVvgContractStartDate = basketStore.basket.persons.find(
      person => person.personId === personId
    ).vvgContractStartDate
    return {
      cancellationExisting,
      preInsurerId: null,
      cancellationDate: DateTimeUtil.getDayBefore(personVvgContractStartDate),
      preInsurerCustomerNumber: null,
      cancelAllProducts: null,
      products: null,
    }
  }

  function hasEmptyPreInsurer(personPreInsurerVvgs) {
    const noPreInsurerVvg = !personPreInsurerVvgs.length
    const hasSingleEmptyPreInsurerVvg = hasSingleEmptyPreInsurer(personPreInsurerVvgs)
    return noPreInsurerVvg || hasSingleEmptyPreInsurerVvg
  }

  function hasSingleEmptyPreInsurer(personPreInsurerVvgs) {
    return personPreInsurerVvgs.length === 1 && personPreInsurerVvgs.every(p => p.cancellationExisting === false)
  }

  function filterPersonsWithCancellation(personsWithCancellationQuestions) {
    const { mutable: mutablePersons } = getPersonsByMutability(basketStore.basket.persons)
    return mutablePersons.filter(mutablePerson =>
      personsWithCancellationQuestions.find(p => p.personId === mutablePerson.personId)
    )
  }

  return {
    createPreInsurerVvg,
    filterPersonsWithCancellation,
    hasEmptyPreInsurer,
    hasSingleEmptyPreInsurer,
  }
}
