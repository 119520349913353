import {
  browserLanguage,
  getLangTranslations,
  handlePlaceholder,
  selectedLanguage,
  setLangTranslations,
  t,
  te,
} from '@/plugins/i18n'

export default function useI18n() {
  // METHODS
  async function loadAideTranslation(language) {
    const content = getLangTranslations(language)

    if (!content['aide']) {
      const messages = await import(`@/language/aide/aide.${language.toLowerCase()}.json`)
      content['aide'] = messages.default
    }

    setLangTranslations(language, content)
    return content
  }

  async function updateTranslations({ language = selectedLanguage.value, isAide = false }) {
    const languageLowerCase = language.toLowerCase()
    let content = getLangTranslations(language)

    if (!content) {
      content = await import(`@/language/content/content.${languageLowerCase}.json`).then(res => res.default)
    }

    if (isAide && !content['aide']) {
      const aideMessages = await import(`@/language/aide/aide.${languageLowerCase}.json`)
      content['aide'] = aideMessages.default
    }

    // set selectedLanguage and selectedLanguage message
    // @TODO can be optimised to only set if not already done
    setLangTranslations(language, content)
    updateHtmlPageData(language)

    return language
  }

  function updateHtmlPageData(lang) {
    // update html language attribute
    document.documentElement.setAttribute('lang', lang)
    const meta = t('meta')
    // check, if DVP-"app" is present
    if (document.querySelector('body[data-track-app=DVP]')) {
      document.querySelector('title').innerText = [import.meta.env.VITE_PAGE_TITLE_PREFIX, meta.title]
        .filter(x => !!x)
        .join(' ')
      document.querySelector('meta[name=description]').content = meta.description
    }
  }

  return {
    browserLanguage,
    handlePlaceholder,
    loadAideTranslation,
    selectedLanguage,
    t,
    te,
    updateTranslations,
  }
}
