<template>
  <!-- prettier-ignore -->
  <button
    aria-label="Close"
    class="close-button"
    type="button"
    :class="classes"
    @click="$emit('click')"
  >
    <the-icon
      art="solid"
      name="xmark"
    />
  </button>
</template>

<script setup>
import { computed } from 'vue'

defineEmits(['click'])

const props = defineProps({
  size: {
    type: String,
    default: 'sm',
    validator(value) {
      return ['sm', 'lg'].includes(value)
    },
  },
})

// COMPUTED
const classes = computed(() => {
  return {
    [`close-button--${props.size}`]: true,
  }
})
</script>

<style scoped>
.close-button {
  &--sm,
  &--lg {
    --dvp-close-btn-font-size: 24px;
    --dvp-close-btn-dimensions: 30px;
  }

  font-size: var(--dvp-close-btn-font-size);
  background: none;
  border: none;
  color: var(--Interaction-States-enabled-on);
  cursor: pointer;
  border-radius: 4px;
  height: var(--dvp-close-btn-dimensions);
  width: var(--dvp-close-btn-dimensions);
  line-height: var(--dvp-close-btn-dimensions);

  &:hover {
    color: var(--Interaction-States-hovered-default-a);
  }

  &:active {
    color: var(--Interaction-States-pressed-default-a);
  }

  &:focus-visible {
    outline: 2px solid var(--dvp-input-c-outline);
    outline-offset: var(--fixed-spacing-fix-01);
  }
}

@media (--v-large) {
  .close-button--lg {
    --dvp-close-btn-font-size: 40px;
    --dvp-close-btn-dimensions: 48px;
  }
}
</style>
