import { computed, reactive, readonly } from 'vue'
import { add } from 'date-fns'

import { events$ } from '@/services'

import { DateTimeHelper } from '@/helpers/DateTime'
import { logError, logInfo } from '@/helpers/Logger'

import { SESSION_EOL, STORAGE_KEY } from '@/config/constants'
import { EVENT_SESSION } from '@/config/events'
import { ReactivityUtil } from '@/utils/Reactivity'

// DATA
const consoleColor = 'color: green'
const store = reactive({
  active: false,
  basketId: null,
})
// Not alphabetically as the store needs to be initialized first
const session = readonly(store)

function sessionStore() {
  // TODO: Move to useStorage
  function readFromStorage(key) {
    const item = window.localStorage.getItem(key)
    let value
    try {
      value = item && JSON.parse(item)
    } catch (e) {
      logError(['Failed to get session from localstorage\n', e, item])
    }

    const hasExpired = (value && value.eol && !DateTimeHelper.isInFuture(new Date(value.eol))) || !value?.basketId

    if (!value || hasExpired) {
      return false
    } else {
      return { ...value }
    }
  }

  function removeFromStorage(key) {
    window.localStorage.removeItem(key)
  }

  function writeToStorage(key, payload) {
    window.localStorage.setItem(key, JSON.stringify(payload))
  }

  // METHODS
  function clearSession() {
    if (!store.active) return Promise.reject()

    const basketId = store.basketId
    // TODO: Check if reAssign is enough or the whole object should be replaced
    ReactivityUtil.reAssign(store, { active: false })

    if (basketId) {
      logInfo(['%cCLEARED SESSION', consoleColor, basketId])
    }

    removeFromStorage(STORAGE_KEY)
    return Promise.resolve()
  }

  /**
   * save "session" into localStorage item
   *
   * @param <Object>payload - basketObject
   * @params <Object>options - hot; setOwner;
   *
   */
  function setSession(payload, options = { hot: true }) {
    const localSession = readFromStorage(STORAGE_KEY)
    const { basketId, jwToken, updateDate } = payload
    const currentSession = { basketId, jwToken, updateDate: updateDate?.substring(0, 24) }

    if (localSession.hot) {
      currentSession.eol = add(new Date(), SESSION_EOL)
      currentSession.hot = true
    } else {
      if ((!payload.email || !payload.mobile) && options.hot) {
        currentSession.eol = add(new Date(), SESSION_EOL)
      }
      currentSession.hot = options.hot
    }

    // log basketId to instana
    if (window.ineum) {
      window.ineum('user', basketId)
    }

    currentSession.active = true
    currentSession.jwToken = jwToken
    ReactivityUtil.reAssign(store, currentSession)

    writeToStorage(STORAGE_KEY, currentSession)

    logInfo(['%cSESSION UPDATED', consoleColor, basketId])
    events$.emit(EVENT_SESSION.UPDATE, basketId)
    // TODO: Return store or nothing at all?
    return Promise.resolve(currentSession)
  }

  return {
    clearSession,
    isActive: computed(() => session.active),
    session,
    setSession,
  }
}

export default sessionStore()
