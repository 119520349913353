import { computed, ref } from 'vue'

// GLOBAL DATA
const viewports = [
  { name: 'small', query: window.matchMedia(`(min-width: 0px)`) },
  { name: 'medium', query: window.matchMedia(`(min-width: 672px)`) },
  { name: 'large', query: window.matchMedia(`(min-width: 1056px)`) },
  { name: 'wide', query: window.matchMedia(`(min-width: 1312px)`) },
  { name: 'ultra', query: window.matchMedia(`(min-width: 1584px)`) },
  { name: 'max', query: window.matchMedia(`(min-width: 1920px)`) },
]

const smViewports = ['small', 'medium']
const currentViewport = ref(viewports.findLast(v => v.query.matches).name)

window.addEventListener('resize', () => {
  currentViewport.value = viewports.findLast(v => v.query.matches).name
})

export default function useBrowser() {
  const isSmallOnlyViewport = computed(() => {
    return currentViewport.value === 'small'
  })

  const isSMViewport = computed(() => {
    return smViewports.includes(currentViewport.value)
  })

  function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  }

  return {
    viewports,
    currentViewport,

    // COMPUTED
    isSMViewport,
    isSmallOnlyViewport,

    // METHODS
    isSafari,
  }
}
