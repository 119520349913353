import axios from 'axios'

import useBackend from '@/hooks/useBackend'

import config from '@/config'

const CancelToken = axios.CancelToken
let cancelFindProviders

function masterData() {
  // HOOKS
  const { doGet, doPost, setup } = useBackend()

  // INIT
  setup('/masterdata', config.microserviceBaseUrl)

  // METHODS

  /**
   * Find current care providers / medical office centers
   *
   * interface ProviderPayload {
   *   avmNetworks?: string[]             // AVM doctor networks
   *   contractStartDate: LocalDateTime
   *   limit?: number
   *   name?: string                      // Search query for the doctor name
   * }
   *
   * interface ProviderGeoPayload extends ProviderPayload {
   *   maxLat: number
   *   minLat: number
   *   maxLon: number
   *   minLon: number
   * }
   *
   * interface ProviderZipCodePayload extends ProviderPayload {
   *   zipCode: number
   * }
   *
   * interface ProviderResponse {
   *   data: Provider[]
   * }
   *
   * @param {ProviderGeoPayload | ProviderZipCodePayload} payload
   * @returns {Promise<ProviderResponse>}
   */
  async function findProviders({ query, ...params }) {
    if (cancelFindProviders) {
      cancelFindProviders()
    }

    const payload = {
      q: query,
      ...params,
      // An executor function receives a cancel function as a parameter
      cancelToken: new CancelToken(function executor(c) {
        cancelFindProviders = c
      }),
    }
    const response = await doPost({ url: '/care-providers', data: payload })
    return response.data
  }

  async function getHouseLocation({ housenumber, street, zipcode }) {
    const response = await doGet({ url: '/locations/houselocation', params: { housenumber, street, zipcode } })
    return response.data
  }

  async function getHouseNumbers({ housenumber, street, zipcode }) {
    const response = await doGet({ url: '/locations/housenumbers', params: { housenumber, street, zipcode } })
    return response.data
  }

  async function getLocations({ query, ...params }) {
    const payload = {
      q: (query || '').trim(),
      ...params,
    }
    const response = await doGet({ url: '/locations', params: payload })
    return response.data
  }

  async function getPreInsurers({ channel, contractStartDate, insurerType }) {
    const response = await doGet({ url: '/preinsurers', params: { channel, contractStartDate, insurerType } })
    // FLEX-3772: We no longer want the user to be able to select 'Sanitas' (id: 362) as a pre-insurer
    return response.data.filter(d => d.id !== '362')
  }

  async function getStreets({ street, zipcode }) {
    const response = await doGet({ url: '/locations/streets', params: { street, zipcode } })
    return response.data
  }

  return {
    findProviders,
    getHouseLocation,
    getHouseNumbers,
    getLocations,
    getPreInsurers,
    getStreets,
  }
}

export default masterData()
