<template>
  <!-- prettier-ignore -->
  <font-awesome-icon
    class="icon"
    :class="{'icon--tooltip': hasTooltip}"
    :icon="[prefix, $props.name]"
    v-bind="$attrs"
    @click="preventToggling"
  />
</template>

<script setup>
import { computed, getCurrentInstance } from 'vue'

import FontAwesomeIcon from '@/config/icons'

// INIT
const props = defineProps({
  art: {
    type: String,
    required: true,
  },

  name: {
    type: String,
    required: true,
  },

  preventInputToggle: {
    type: Boolean,
    default: false,
  },
})

// COMPUTED
const hasTooltip = computed(() => {
  const instance = getCurrentInstance()

  return instance.vnode?.dirs?.length
})

const prefix = computed(() => {
  switch (props.art) {
    case 'duo':
      return 'fad'

    case 'light':
      return 'fal'

    case 'regular':
      return 'far'

    case 'solid':
      return 'fas'

    case 'thin':
      return 'fat'

    default:
      return console.error('invalid art selected')
  }
})

// METHODS
function preventToggling(event) {
  if (!props.preventInputToggle) return

  event.stopPropagation()
  event.preventDefault()
}
</script>

<style name="mobile" scoped>
.icon {
  color: inherit; /* var(--c-primary-color-3); */
  transition: color 0.3s ease-in-out;
  font-size: inherit;

  &--tooltip {
    color: var(--Interaction-States-enabled-default);
  }
}
</style>
