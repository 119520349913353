import config from '@/config'

import useBackend from '@/hooks/useBackend'

function contractStartDate() {
  // HOOKS
  const { doGet, setup } = useBackend()

  // INIT
  setup('/ovpv3/public', config.microserviceBaseUrl)

  // METHODS

  /**
   * getDefaultContractStartDate sends a GET-request to /contractStartDate/default to get the default kvgContractStartDate and vvgContractStartDate
   * @returns {Promise<string>} default contractStartDate
   */
  async function getDefaultContractStartDate() {
    const response = await doGet({ url: '/contractStartDate/default' })
    return response.data
  }

  return {
    getDefaultContractStartDate,
  }
}

export default contractStartDate()
