import { cloneDeep } from 'lodash'

import events$ from '@/services/Events'

import { haXProducts } from '@/utils/HaX'
import { logInfo } from '@/helpers/Logger'
import { DateTimeHelper } from '@/helpers/DateTime'

import basketAPI from '@/api/basket'
import productAPI from '@/api/product'
import basketStore from '@/store/basket'
import productStructureStore from '@/store/productStructure'
import useContract from '@/hooks/useContract'
import useI18n from '@/hooks/useI18n'
import usePerson from '@/hooks/usePerson'
import usePersonDetails from '@/hooks/usePersonDetails'
import useProduct from '@/hooks/useProduct'

import { isRequired } from '@/utils/CustomErrors'
import {
  getSortedProductsFromGroup,
  getSortedGroupsFromCategory,
  getSortedProductsFromCategory,
  sortProducts,
  updatePriceOptions,
} from '@/utils/Product'
import { SortUtil } from '@/utils/Sorting'

import { CATEGORY_TYPE, NOTIFICATION, PRODUCT_MODIFICATION_TYPE, PRODUCT_STATE } from '@/config/constants'
import { EVENT_FAB, EVENT_PRODUCT } from '@/config/events'
import { updatePersonInBasket } from '@/utils/Person'
import { removeUnusedDoctors } from '@/utils/Doctor'

export default function usePersonSpecificProduct() {
  /**
   * @file usePersonSpecificProduct contains logic for products involving a single specific person
   */

  // METHODS
  async function customizeProduct(payload, source) {
    const { categoryId, personId, productId, option } = payload

    let __basket = await selectProductOption({ personId, productId, option })
    __basket = await basketStore.updateOvpBasket(__basket)

    events$.emit(EVENT_PRODUCT.CHANGED, {
      basket: __basket,
      categoryId,
      option,
      personId,
      productId,
      source,
    })

    return __basket
  }

  function getCategoriesByPersonId(personId = isRequired('personId')) {
    const { getPerson } = usePerson()
    const personCategories = getPerson(personId)?.products?.categories ?? {}

    if (!personCategories) return []

    return getSortedCategories(productStructureStore.categories.value)
      .filter(category => !!personCategories[category.id])
      .map(category => Object.assign(category, personCategories[category.id]))
      .sort(SortUtil.sortByOrder)
  }

  function getCategory(categoryId = isRequired('categoryId'), personId = isRequired('personId')) {
    return getCategoriesByPersonId(personId).find(c => c.id === categoryId)
  }

  /**
   * getGroupsFromCategory
   * @param {string} personId
   * @param {string} categoryId
   * @returns {Category}
   */
  function getGroupsFromCategory(personId, categoryId) {
    const { getPerson } = usePerson()
    const __groups = getPerson(personId)?.products?.groups ?? {}

    return getProductGroupsFromCategory(categoryId)
      .filter(group => !!__groups[group.id])
      .map(group => Object.assign(group, __groups[group.id]))
      .sort(SortUtil.sortByOrder)
  }

  /**
   * getPriceCalculationsForProduct calculates all the price variants for person's product
   * @note: the result of this can't be used for e.g. the productModal, as it wont contain everything related to the contract/offer
   * @param {String} productId
   * @param {Object} person
   *
   * @returns {Promise<PriceCombination[]>}
   *
   */
  async function getPriceCalculationsForProduct(productId, person) {
    const { getCategoryFromProduct } = useProduct()
    const selectedProduct = getProduct(person.personId, productId)
    const selectedPrice = selectedProduct.prices.find(p => p.selected)
    const productCategory = getCategoryFromProduct(productId)
    const payload = {
      address: basketStore.basket.address,
      collective: basketStore.basket.collective,
      collectivePersonGroup: person.collectivePersonGroup,
      contractNumber: basketStore.basket.contractNumber,
      contractStartDate:
        productCategory.id === CATEGORY_TYPE.KVG ? person.kvgContractStartDate : person.vvgContractStartDate,
      dateOfBirth: person.personData.dateOfBirth,
      gender: person.personData.gender,
      masterTreaty: basketStore.basket.masterTreaty,
      selectedPriceCombination: selectedPrice,
      zsrNumber: selectedProduct.doctor?.zsrNumber,
    }

    return await productAPI.calculateAllPricesForProduct(productId, payload)
  }

  async function getSimulatedProductSelection(productId, personId) {
    const { isUpgrade } = useProduct()

    let basket = cloneDeep(basketStore.basket)
    const mode = isUpgrade(productId) ? 'ADD' : 'REPLACE'

    //basket = await addProductToOvpBasket(personId, productId, { basket, mode })
    const pBasket = mutateProductsInBasketForPerson(personId, productId, { basket, mode })
    basket = updatePersonInBasket(basket, personId, pBasket)

    basket = await basketAPI.simulateUpdateBasket({ personId }, basket)

    return Object.assign({}, basket.persons.find(p => p.personId === personId).products.products[productId], {
      productId,
      selected: false,
    })
  }

  /**
   * getProduct returns the product depending on the personId and productId
   * @param {string} personId
   * @param {string} productId
   * @returns {Product}
   */
  function getProduct(personId, productId) {
    const { getPerson } = usePerson()
    return getPerson(personId)?.products?.products[productId]
  }

  function getProductMessages({ categoryId, hasCalculationWarnings, modalRef, personId, productState, product }) {
    const { isUnborn, getPerson } = usePerson()
    const { t, te } = useI18n()
    const data = []

    if (productState !== PRODUCT_STATE.EDITABLE) {
      data.push({
        closeable: false,
        content: t('product.detail.cancellation.message'),
        severity: NOTIFICATION.WARNING,
      })
    }

    if (hasCalculationWarnings) {
      data.push({
        closeable: false,
        content: t('product.detail.message', { servicePortalText: product.calculationWarnings[0] }),
        severity: NOTIFICATION.INFO,
      })
    }

    const key = `content.products.${product.productId}`
    if (isUnborn(personId) && te(`${key}.additionalInfoUnborn`)) {
      data.push({
        closeable: false,
        content: t(`${key}.additionalInfoUnborn`),
        severity: NOTIFICATION.INFO,
      })
    } else if (te(`${key}.additionalInfo`)) {
      data.push({
        closeable: false,
        content: t(`${key}.additionalInfo`),
        severity: NOTIFICATION.INFO,
      })
    }

    // @note: FLEX-3284
    const _person = getPerson(personId)
    const _age = DateTimeHelper.getAge(_person.personData.dateOfBirth, _person.kvgContractStartDate)
    if (product.productId === 'Z04Produkt' && _age >= 5) {
      data.push({
        actions: [
          {
            label: t('content.products.Z04Produkt.ageMessage.title'),
            callback: () => {
              events$.emit(EVENT_FAB.OPEN)
              modalRef?.close()
            },
          },
        ],
        content: t('content.products.Z04Produkt.ageMessage.text'),
        severity: NOTIFICATION.INFO,
      })
    }

    if (
      !hasCalculationWarnings &&
      product.riskAssessmentRequired === false &&
      product.selected === false &&
      categoryId !== CATEGORY_TYPE.KVG
    ) {
      data.push({
        closeable: false,
        content: t('screen.products.noRiskAssessmentRequired'),
        severity: NOTIFICATION.INFO,
      })
    }

    if (!product.prices && te(`content.products.${product.productId}.unavailable`)) {
      data.push({
        closeable: false,
        content: t(`content.products.${product.productId}.unavailable`),
        severity: NOTIFICATION.WARNING,
      })
    }

    if (product.conditionalProduct) {
      data.push({
        closeable: false,
        content: t('person.caveats.product'),
        severity: NOTIFICATION.WARNING,
      })
    }

    const errors = product?.messages?.filter(m => m.type !== 'info') || []
    if (errors.length > 0) {
      errors.forEach(error => {
        data.push({
          actions: [],
          closeable: false,
          content: t(`error.${error.key}`),
          severity: error.type.toLowerCase(),
        })
      })
    }

    return data
  }

  /**
   * getProductPrice returns the price of a product for a given personId and productId
   * @param {string} personId
   * @param {string} productId
   * @returns {Product}
   */
  function getProductPrice(personId, productId) {
    return getProduct(personId, productId).prices.find(p => !!p.selected)
  }

  /**
   * getProductsFromCategory
   * @param {string} categoryId
   * @param {string} personId
   * @returns {}
   */
  function getProductsFromCategory(categoryId, personId) {
    const __products = personId ? getPersonProducts(personId) : productStructureStore.products.value
    return sortProducts(
      getSortedProductsFromCategory({
        categories: productStructureStore.categories.value,
        groups: productStructureStore.groups.value,
        products: __products,
        categoryId,
      }),
      __products
    )
  }

  /**
   * getProductsFromGroup
   * @param {string} groupId
   * @param {string} personId
   * @returns {}
   */
  function getProductsFromGroup(groupId, personId) {
    const __products = personId ? getPersonProducts(personId) : productStructureStore.products.value

    // pass groups and products from structure, not from the person -> because we need the config-flags from the group/product, e.g. medicalOffice
    return sortProducts(
      getSortedProductsFromGroup(productStructureStore.groups.value, productStructureStore.products.value, groupId),
      __products
    )
  }

  /**
   * Groups the selected products of a user by the category
   * and sorts both the products and the categories by order
   * @param {Array} selectedProducts Selected products of a person
   * @return []
   */
  function getSelectedProductsByCategory(selectedProducts = isRequired('selected products')) {
    if (selectedProducts.length === 0) return selectedProducts

    const { getCategoryIdFromProduct, getGroupFromProduct } = useProduct()
    const __selectedProducts = cloneDeep(selectedProducts)

    const productsByCategory = {}
    const unknownProducts = []

    Object.values(__selectedProducts).forEach(__product => {
      const categoryId = getCategoryIdFromProduct(__product.productId)
      const group = getGroupFromProduct(__product.productId)

      // add the grouping to the order
      __product.sort = `${group?.order}-${__product.order}`

      categoryId
        ? (productsByCategory[categoryId] = [...(productsByCategory[categoryId] || []), __product])
        : unknownProducts.push(__product)
    })

    unknownProducts.forEach(__product => {
      const categoryId = Object.keys(productsByCategory).find(c =>
        productsByCategory[c].find(pc => pc?.upgrades?.includes(__product.id))
      )
      if (categoryId) {
        // Upgrade products
        productsByCategory[categoryId].push(__product)
      } else {
        // Old products without currently existing category (used for 'Bestandeskunden')
        productsByCategory[CATEGORY_TYPE.UNKONWN] = [...(productsByCategory[CATEGORY_TYPE.UNKONWN] || []), __product]
      }
    })

    Object.keys(productsByCategory).forEach(categoryId => {
      productsByCategory[categoryId].sort(SortUtil.sortByParam('sort'))
    })

    return (
      Object.keys(productsByCategory)
        .map(categoryId => {
          return { id: categoryId, products: productsByCategory[categoryId] }
        })
        /**
         * Get order of category with given categoryId
         * If category not found, return high order number to show this category as last
         * (needed for existing customers with old products)
         */
        .sort(
          SortUtil.sortByParam(
            item => Object.values(productStructureStore.categories.value).find(c => c.id === item.id)?.order || 1000
          )
        )
    )
  }

  /**
   * getSortedCategories returns all categories in an array sorted by order field
   * @param categories
   * @return []
   */
  function getSortedCategories(categories = isRequired('categories')) {
    const data = []
    const categoriesCopy = cloneDeep(categories)
    Object.keys(categoriesCopy).forEach(categoryId => data.push(categoriesCopy[categoryId]))
    return data.sort(SortUtil.sortByOrder)
  }

  /**
   *  hasPersonKVG returns true if a person has at least one KVG product selected
   *  @param {object} __selectedProducts
   *  @returns {boolean}
   */
  function hasPersonKVG(__selectedProducts) {
    const productCategories = getSelectedProductsByCategory(__selectedProducts)

    return productCategories.some(category => category.id === CATEGORY_TYPE.KVG)
  }

  /**
   *  hasPersonKVGModified returns true if a person has at least one KVG product selected marked as modified (added or changed)
   *  @param {object} __selectedProducts
   *  @returns {boolean}
   */
  function hasPersonKVGModified(__selectedProducts) {
    const filteredSelectedProducts = __selectedProducts.filter(
      product =>
        product.selected &&
        [PRODUCT_MODIFICATION_TYPE.NEW, PRODUCT_MODIFICATION_TYPE.CHANGED].includes(product.modificationType)
    )

    const productCategories = getSelectedProductsByCategory(filteredSelectedProducts)

    return productCategories.some(category => category.id === CATEGORY_TYPE.KVG)
  }

  /**
   *  hasPersonVVGModified returns true if a person has at least one VVG product selected marked as modified (added or changed)
   *  @param {object} __selectedProducts
   *  @returns {boolean}
   */
  function hasPersonVVGModified(__selectedProducts) {
    const filteredSelectedProducts = __selectedProducts.filter(
      product =>
        product.selected &&
        [PRODUCT_MODIFICATION_TYPE.NEW, PRODUCT_MODIFICATION_TYPE.CHANGED].includes(product.modificationType)
    )

    const productCategories = getSelectedProductsByCategory(filteredSelectedProducts)

    return productCategories.filter(category => category.id !== CATEGORY_TYPE.KVG).length > 0
  }

  /**
   *  hasPersonOnlyKVG returns true if a person has only selected a KVG product (but no VVG products)
   *  @todo circular dependency with usePerson
   *  @param {string} personId
   *  @returns {boolean}
   */
  function hasPersonOnlyKVG(personId) {
    const { getPersonWithDetails } = usePersonDetails()
    const __selectedProducts = getPersonWithDetails(personId).selectedProducts
    const productCategories = getSelectedProductsByCategory(__selectedProducts)
    return productCategories.length === 1 && productCategories[0].id === CATEGORY_TYPE.KVG
  }

  /**
   *  hasPersonOnlyVVG returns true if a person has only VVG products selected (but no KVG product)
   *  @todo circular dependency with usePerson
   *  @param {string} personId
   *  @returns {boolean}
   */
  function hasPersonOnlyVVG(personId) {
    const { getPersonWithDetails } = usePersonDetails()
    const __selectedProducts = getPersonWithDetails(personId).selectedProducts
    const productCategories = getSelectedProductsByCategory(__selectedProducts)
    return productCategories.length > 0 && !productCategories.find(category => category.id === CATEGORY_TYPE.KVG)
  }

  /**
   *  hasPersonVVG returns true if a person has at least on VVG product selected
   *  @param {object} __selectedProducts
   *  @returns {boolean}
   */
  function hasPersonVVG(__selectedProducts) {
    const productCategories = getSelectedProductsByCategory(__selectedProducts)
    return productCategories.some(category => ![CATEGORY_TYPE.KVG].includes(category.id))
  }

  /**
   *  isProductInBasket
   *  @param {string} personId
   *  @param {string} productId
   *  @returns {boolean}
   */
  function isProductInBasket(personId = isRequired('personId'), productId = isRequired('productId')) {
    const { getPerson } = usePerson()
    const person = getPerson(personId)
    return !!person.products.products[productId].selected
  }

  /**
   *  mutateProductsInBasketForPerson
   *  @param {string} personId
   *  @param {string} productId
   *  @param {{mode: string, basket: Basket}} [options={}]
   */
  function mutateProductsInBasketForPerson(personId, productId, options = {}) {
    const { getPerson, isExistingCustomer } = usePerson()
    const mode = options.mode ?? 'REPLACE'
    const ___basket = options.basket ?? null

    const pBasket = ___basket ? ___basket.persons.find(p => p.personId === personId) : getPerson(personId)
    const products = pBasket.products.products

    const addingProductIds = []
    let removingProductIds = []

    switch (mode) {
      case 'ADD':
        // we know for sure, that there's no product that needs to be replaced,
        // only add the selected product
        addingProductIds.push(productId)
        break

      case 'REMOVE':
        if (isExistingCustomer(personId)) {
          addingProductIds.push(...getReplaceableExistingProductsFromContract(getPerson(personId), productId))
        }
        removingProductIds.push(productId)
        break

      case 'REPLACE':
        if (isExistingCustomer(personId)) {
          removingProductIds.push(...getReplaceableExistingProductsFromContract(getPerson(personId), productId))

          // @note: check, if product is also selected in basket. otherwise remove from array
          removingProductIds = removingProductIds.filter(_productId => products[_productId]?.selected)
        }

        if (removingProductIds.length === 0) {
          removingProductIds.push(...getReplaceableProductsFromBasket(personId, productId))
        }

        addingProductIds.push(productId)
    }

    logInfo(['%cADDING PRODUCTS', 'color: green', addingProductIds])
    logInfo(['%cREMOVING PRODUCTS', 'color: green', removingProductIds])

    pBasket.products.products = mutatePersonProducts(products, addingProductIds, removingProductIds)
    return pBasket
  }

  async function removeProductFromPerson(personId = isRequired('personId'), productId = isRequired('productId')) {
    const pBasket = mutateProductsInBasketForPerson(personId, productId, { mode: 'REMOVE' })
    let updatedBasket = updatePersonInBasket(cloneDeep(basketStore.basket), personId, pBasket)
    updatedBasket = removeUnusedDoctors(updatedBasket)
    return await basketStore.updateOvpBasket(updatedBasket)
  }

  /**
   *  selectProductOption
   *  @param {{personId: string, product: Object, option: object, basket: Basket}}
   *  @returns {Promie<Basket>}
   */
  function selectProductOption({ personId, product, option, basket = cloneDeep(basketStore.basket) }) {
    const pBasket = basket.persons.find(p => p.personId === personId)

    product.prices = updatePriceOptions(product.prices, option)
    pBasket.products.products[product.productId] = product

    return Promise.resolve(updatePersonInBasket(basket, personId, pBasket))
  }

  /**
   * selectProductOptionForCategory
   * @param {string} personId
   * @param {string} categoryId
   * @param {} option
   *
   * @returns {Promise<Basket>}
   */
  function selectProductOptionForCategory({ personId, categoryId, option }) {
    const data = {
      products: [],
      mutations: [],
    }

    const products = getProductsFromCategory(categoryId, personId)
    getGroupsFromCategory(personId, categoryId)
      .reduce((acc, val) => acc.concat(val.products), [])
      .forEach(productId => {
        data.products.push(productId)
        data.mutations.push(
          selectProductOption({
            personId,
            product: products.find(p => p.productId === productId),
            option,
          })
        )
      })

    return Promise.all(data.mutations).then(fragments => {
      const __products = fragments.reduce((acc, value, index) => {
        const productId = data.products[index]
        acc[productId] = value.persons.find(p => p.personId === personId).products.products[productId]
        return acc
      }, {})

      const __basket = fragments[0]
      const personIndex = __basket.persons.findIndex(p => p.personId === personId)

      __basket.persons[personIndex].products.products = Object.assign(
        {},
        __basket.persons[personIndex].products.products,
        __products
      )

      return Promise.resolve(__basket)
    })
  }

  async function changeProductOption({ option, personId, product, basket = cloneDeep(basketStore.basket) }) {
    if (!option.zsrNumber) {
      basket = await selectProductOption({ personId, product, option, basket })
      product = basket.persons.find(p => p.personId === personId).products.products[product.productId] = Object.assign(
        {},
        product,
        basket.persons.find(p => p.personId === personId).products.products[product.productId]
      )
    } else {
      basket.persons.find(p => p.personId === personId).products.products[product.productId] = product
      product.doctor = option
    }

    basket = await basketStore.updateOvpBasket(basket, { simulate: true })

    return Object.assign(
      {},
      product,
      basket.persons.find(p => p.personId === personId).products.products[product.productId]
    )
  }

  /**
   * update a product for person
   *
   * @param {String} personId
   * @param {Object} product
   * @param {Object} basket
   */
  function updateProductForPerson({ personId, product, basket = cloneDeep(basketStore.basket) }) {
    basket.persons.find(p => p.personId === personId).products.products[product.productId] = product

    return basket
  }

  /**
   *  willReplace
   *  @param {string} personId
   *  @param {string} productId
   *  @returns {}
   */
  function willReplace(personId, productId) {
    const { getReplaceableProducts } = useProduct()
    const replaceableProducts = getReplaceableProducts(productId)
    return replaceableProducts.find(__productId => getProduct(personId, __productId)?.selected)
  }

  // PRIVATE METHODS

  function extractProductIdFromProduct(acc, product) {
    acc.push(product.productId)
    return acc
  }
  /**
   * getPersonProducts
   * @param {string} personId
   * @returns {}
   */
  function getPersonProducts(personId) {
    const { getPerson } = usePerson()
    return getPerson(personId)?.products?.products ?? {}
  }

  /**
   * getProductGroupsFromCategory
   * @param {string} categoryId
   */
  function getProductGroupsFromCategory(categoryId) {
    return getSortedGroupsFromCategory(
      productStructureStore.categories.value,
      productStructureStore.groups.value,
      categoryId
    )
  }
  /**
   *  replaceExistingProductWithSelected
   */
  function getReplaceableExistingProductsFromContract(person, productId) {
    if (!person.partnerNumber) return []

    const { getSelectedProductsOfContractPerson } = useContract()
    const { getGroupFromProduct } = useProduct()
    const groupSelected = getGroupFromProduct(productId)

    // @note: either the product is not in a group, or it's not exclusive
    if (!groupSelected || !groupSelected.exclusive) return []

    const contractProducts = getSelectedProductsOfContractPerson(person.partnerNumber)

    const contractGroupId = product =>
      Object.keys(haXProducts.groups).find(groupId => haXProducts.groups[groupId].products.includes(product.productId))

    return contractProducts
      .filter(contractProduct => contractGroupId(contractProduct) === groupSelected.id)
      .reduce(extractProductIdFromProduct, [])
  }

  function getReplaceableProductsFromBasket(personId, productId) {
    const { getCategoryFromProduct, getGroupFromProduct } = useProduct()
    const category = getCategoryFromProduct(productId)
    const group = getGroupFromProduct(productId)

    if (category?.exclusive) {
      return getProductsFromCategory(category.id, personId)
        .filter(product => product.productId !== productId)
        .reduce(extractProductIdFromProduct, [])
    }

    if (group?.exclusive) {
      return getProductsFromGroup(group.id, personId)
        .filter(product => product.productId !== productId)
        .reduce(extractProductIdFromProduct, [])
    }

    return []
  }
  function mutatePersonProducts(products, addingProductIds, removingProductIds) {
    const productKeys = Object.keys(products)
    const personProducts = {}

    productKeys.forEach(productKey => {
      if (addingProductIds.includes(productKey)) {
        personProducts[productKey] = Object.assign({}, products[productKey], { selected: true })
      } else if (removingProductIds.includes(productKey)) {
        personProducts[productKey] = Object.assign({}, products[productKey], { selected: false })
      } else {
        personProducts[productKey] = products[productKey]
      }
    })

    return personProducts
  }

  return {
    // METHODS
    customizeProduct,
    getCategoriesByPersonId,
    getCategory,
    getGroupsFromCategory,
    getPriceCalculationsForProduct,
    getProduct,
    getProductMessages,
    getProductPrice,
    getProductsFromCategory,
    getProductsFromGroup,
    getSelectedProductsByCategory,
    getSimulatedProductSelection,
    getSortedCategories,
    hasPersonKVG,
    hasPersonKVGModified,
    hasPersonOnlyKVG,
    hasPersonOnlyVVG,
    hasPersonVVG,
    hasPersonVVGModified,
    isProductInBasket,
    mutateProductsInBasketForPerson,
    removeProductFromPerson,
    selectProductOption,
    selectProductOptionForCategory,
    changeProductOption,
    willReplace,

    updateProductForPerson,
  }
}
