import { throttle } from 'lodash'

import useBrowser from '@/hooks/useBrowser'

const { isSMViewport } = useBrowser()

function stickTo(container) {
  const staticElement = document.querySelector('.aide__content')
  const scrollTargets = container.querySelectorAll('.scroll__target')
  const avatar = document.querySelector('img.avatar__image')

  if (!avatar || !scrollTargets) return

  const _length = scrollTargets.length
  const scrollTarget = scrollTargets[_length > 1 ? _length - 1 : 0]

  if (scrollTarget) {
    resetFocusedTargets()

    scrollTarget.classList.add('scroll__target--focused')

    const offsetTop = scrollTarget.offsetTop - staticElement.offsetTop
    const clientHeight = scrollTarget.clientHeight - avatar.clientHeight

    const desktopTopPosition = offsetTop + clientHeight
    const mobileTopPosition = offsetTop - (avatar.clientHeight + 12)

    const topPosition = isSMViewport.value ? mobileTopPosition : desktopTopPosition
    avatar.style.top = `${topPosition}px`
  } else {
    const aideBubblePlaceholder = document.querySelector('.aide__bubble__placeholder')
    if (aideBubblePlaceholder) {
      avatar.style.top = '129px'
    }
  }
}

function resetFocusedTargets() {
  const elements = document.querySelectorAll('.scroll__target--focused')
  elements.forEach(element => {
    element.classList.remove('scroll__target--focused')
  })
}

function stickToElement(el) {
  const _stickToIt = throttle(stickTo, 50)

  window.setTimeout(() => {
    const container = el.parentNode.querySelector('.aide__content')
    stickTo(container)

    const config = { attributes: false, childList: true, subtree: true }
    const observer = new MutationObserver(function (list) {
      for (const mutation of list) {
        if (mutation.type === 'childList') {
          window.setTimeout(() => {
            _stickToIt(container)
          })
        }
      }
    })
    observer.observe(container, config)
  })
}

function observeAvatarPosition() {
  const avatar = document.querySelector('.avatar__image')
  const observer = new MutationObserver(() => {
    if (avatar) {
      const topPosition = avatar.getBoundingClientRect().top
      window.setTimeout(() => {
        if (parseInt(topPosition) !== 0) {
          const scrollTarget = document.querySelector('.scroll__target--focused')
          scrollTarget?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
      }, 500)
    }
  })
  observer.observe(avatar, { attributes: true, attributeFilter: ['style'] })
}

export default {
  beforeMount(el) {
    stickToElement(el)
    const throttledResizeHandler = throttle(() => stickToElement(el), 500)
    window.addEventListener('resize', throttledResizeHandler)
  },
  updated(el) {
    stickToElement(el)
    observeAvatarPosition()
  },
}
