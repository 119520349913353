import eventMitt from '@jswork/event-mitt'

import { logInfo } from '@/helpers/Logger'

class Events {
  constructor() {
    if (Events._instance) {
      return Events._instance
    }
    Events._instance = this
  }

  emit(key, data) {
    logInfo(['%cEVENT.EMIT', 'color: yellow', key, data])
    eventMitt.emit(key, data)
  }

  off(key, callback) {
    eventMitt.off(key, callback)
  }

  on(key, callback) {
    eventMitt.on(key, callback)
  }

  one(key, callback) {
    eventMitt.one(key, callback)
  }

  once(key, callback) {
    eventMitt.once(key, callback)
  }
}

export default new Events()
