import { library } from '@fortawesome/fontawesome-svg-core'

import { faCirclePlus as fatCirclePlus } from '@fortawesome/pro-thin-svg-icons/faCirclePlus'
import { faCircleXmark as fatCircleXmark } from '@fortawesome/pro-thin-svg-icons/faCircleXmark'

library.add(fatCirclePlus)
library.add(fatCircleXmark)

import { faRightLeft as fadRightLeft } from '@fortawesome/pro-duotone-svg-icons/faRightLeft'
import { faHouseMedical as fadHouseMedical } from '@fortawesome/pro-duotone-svg-icons/faHouseMedical'
import { faSpinnerThird as fadSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird'
import { faLoader as fadLoader } from '@fortawesome/pro-duotone-svg-icons/faLoader'
import { faPartyHorn as fadPartyHorn } from '@fortawesome/pro-duotone-svg-icons/faPartyHorn'
import { faRaccoon as fadRaccoon } from '@fortawesome/pro-duotone-svg-icons/faRaccoon'
import { faRadiation as fadRadiation } from '@fortawesome/pro-duotone-svg-icons/faRadiation'
import { faFireFlame as fadFireFlame } from '@fortawesome/pro-duotone-svg-icons/faFireFlame'

library.add(
  fadHouseMedical,
  fadFireFlame,
  fadLoader,
  fadPartyHorn,
  fadRaccoon,
  fadRadiation,
  fadRightLeft,
  fadSpinnerThird
)

import { faAngleDown as fasAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown'
import { faArrowLeft as fasArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft'
import { faArrowLeftLong as fasArrowLeftLong } from '@fortawesome/pro-solid-svg-icons/faArrowLeftLong'
import { faArrowRight as fasArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight'
import { faArrowRightLong as fasArrowRightLong } from '@fortawesome/pro-solid-svg-icons/faArrowRightLong'
import { faArrowRotateLeft as fasArrowRotateLeft } from '@fortawesome/pro-solid-svg-icons/faArrowRotateLeft'
import { faAt as fasAt } from '@fortawesome/pro-solid-svg-icons/faAt'
import { faBabyCarriage as fasBabyCarriage } from '@fortawesome/pro-solid-svg-icons/faBabyCarriage'
import { faCheck as fasCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faCheckCircle as fasCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { faChevronDown as fasChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown'
import { faChevronUp as fasChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp'
import { faCircle as fasCircle } from '@fortawesome/pro-solid-svg-icons/faCircle'
import { faCircleCheck as fasCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck'
import { faCircleExclamation as fasCircleExclamation } from '@fortawesome/pro-solid-svg-icons/faCircleExclamation'
import { faCircleXmark as fasCircleXmark } from '@fortawesome/pro-solid-svg-icons/faCircleXmark'
import { faComments as fasComments } from '@fortawesome/pro-solid-svg-icons/faComments'
import { faCopy as fasCopy } from '@fortawesome/pro-solid-svg-icons/faCopy'
import { faDownload as fasDownload } from '@fortawesome/pro-solid-svg-icons/faDownload'
import { faExclamation as fasExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation'
import { faEnvelope as fasEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faEye as fasEye } from '@fortawesome/pro-solid-svg-icons/faEye'
import { faFilePdf as fasFilePdf } from '@fortawesome/pro-solid-svg-icons/faFilePdf'
import { faHeadset as fasHeadset } from '@fortawesome/pro-solid-svg-icons/faHeadset'
import { faMinus as fasMinus } from '@fortawesome/pro-solid-svg-icons/faMinus'
import { faPen as fasPen } from '@fortawesome/pro-solid-svg-icons/faPen'
import { faPencil as fasPencil } from '@fortawesome/pro-solid-svg-icons/faPencil'
import { faPersonSimple as fasPersonSimple } from '@fortawesome/pro-solid-svg-icons/faPersonSimple'
import { faPersonDressSimple as fasPersonDressSimple } from '@fortawesome/pro-solid-svg-icons/faPersonDressSimple'
import { faPhone as fasPhone } from '@fortawesome/pro-solid-svg-icons/faPhone'
import { faPlus as fasPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { faPrint as fasPrint } from '@fortawesome/pro-solid-svg-icons/faPrint'
import { faSignalWeak as fasSignalWeak } from '@fortawesome/pro-solid-svg-icons/faSignalWeak'
import { faSignalFair as fasSignalFair } from '@fortawesome/pro-solid-svg-icons/faSignalFair'
import { faSignalGood as fasSignalGood } from '@fortawesome/pro-solid-svg-icons/faSignalGood'
import { faStar as fasStar } from '@fortawesome/pro-solid-svg-icons/faStar'
import { faSquare as fasSquare } from '@fortawesome/pro-solid-svg-icons/faSquare'
import { faSquareCheck as fasSquareCheck } from '@fortawesome/pro-solid-svg-icons/faSquareCheck'
import { faTrash as fasTrash } from '@fortawesome/pro-solid-svg-icons/faTrash'
import { faTrashCanXmark as fasTrashCanXmark } from '@fortawesome/pro-solid-svg-icons/faTrashCanXmark'
import { faUpload as fasUpload } from '@fortawesome/pro-solid-svg-icons/faUpload'
import { faUserGroup as fasUserGroup } from '@fortawesome/pro-solid-svg-icons/faUserGroup'
import { faUserNinja as fasUserNinja } from '@fortawesome/pro-solid-svg-icons/faUserNinja'
import { faUserMd as fasUserMd } from '@fortawesome/pro-solid-svg-icons/faUserMd'
import { faVideo as fasVideo } from '@fortawesome/pro-solid-svg-icons/faVideo'
import { faXmark as fasXmark } from '@fortawesome/pro-solid-svg-icons/faXmark'

library.add(
  fasAngleDown,
  fasArrowLeft,
  fasArrowLeftLong,
  fasArrowRight,
  fasArrowRightLong,
  fasArrowRotateLeft,
  fasAt,
  fasBabyCarriage,
  fasCheck,
  fasCheckCircle,
  fasChevronDown,
  fasChevronUp,
  fasCircle,
  fasCircleCheck,
  fasCircleExclamation,
  fasCircleXmark,
  fasComments,
  fasCopy,
  fasDownload,
  fasExclamation,
  fasEnvelope,
  fasEye,
  fasFilePdf,
  fasHeadset,
  fasMinus,
  fasPhone,
  fasPen,
  fasPencil,
  fasPersonSimple,
  fasPersonDressSimple,
  fasPlus,
  fasPrint,
  fasSignalWeak,
  fasSignalFair,
  fasSignalGood,
  fasStar,
  fasSquare,
  fasSquareCheck,
  fasTrash,
  fasTrashCanXmark,
  fasUpload,
  fasUserGroup,
  fasUserNinja,
  fasUserMd,
  fasVideo,
  fasXmark
)

import { faArrowLeft as farArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft'
import { faArrowRight as farArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight'
import { faArrowRotateLeft as farArrowRotateLeft } from '@fortawesome/pro-regular-svg-icons/faArrowRotateLeft'
import { faCheck as farCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'
import { faCircle as farCircle } from '@fortawesome/pro-regular-svg-icons/faCircle'
import { faCircleCheck as farCircleCheck } from '@fortawesome/pro-regular-svg-icons/faCircleCheck'
import { faCircleExclamation as farCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faCircleExclamation'
import { faCircleInfo as farCircleInfo } from '@fortawesome/pro-regular-svg-icons/faCircleInfo'
import { faChevronDown as farChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown'
import { faChevronUp as farChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp'
import { faEnvelope as farEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope'
import { faFile as farFile } from '@fortawesome/pro-regular-svg-icons/faFile'
import { faMagnifyingGlass as farMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlass'
import { faMessages as farMessages } from '@fortawesome/pro-regular-svg-icons/faMessages'
import { faMinus as farMinus } from '@fortawesome/pro-regular-svg-icons/faMinus'
import { faPlus as farPlus } from '@fortawesome/pro-regular-svg-icons/faPlus'
import { faPen as farPen } from '@fortawesome/pro-regular-svg-icons/faPen'
import { faSpinnerThird as farSpinnerThird } from '@fortawesome/pro-regular-svg-icons/faSpinnerThird'
import { faSquare as farSquare } from '@fortawesome/pro-regular-svg-icons/faSquare'
import { faStopwatch as farStopwatch } from '@fortawesome/pro-regular-svg-icons/faStopwatch'
import { faXmark as farXmark } from '@fortawesome/pro-regular-svg-icons'
import { faQuestionCircle as farQuestionCircle } from '@fortawesome/pro-regular-svg-icons/faQuestionCircle'

library.add(
  farArrowLeft,
  farArrowRight,
  farArrowRotateLeft,
  farCheck,
  farCircle,
  farCircleCheck,
  farCircleExclamation,
  farCircleInfo,
  farEnvelope,
  farFile,
  farMagnifyingGlass,
  farMessages,
  farMinus,
  farSpinnerThird,
  farSquare,
  farStopwatch,
  farPen,
  farPlus,
  farXmark,
  farQuestionCircle,
  farChevronDown,
  farChevronUp
)

import { faAngleDown as falAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown'
import { faAngleUp as falAngleUp } from '@fortawesome/pro-light-svg-icons/faAngleUp'
import { faArrowRotateLeft as falArrowRotateLeft } from '@fortawesome/pro-light-svg-icons/faArrowRotateLeft'
import { faArrowLeft as falArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft'
import { faArrowRight as falArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight'
import { faAt as falAt } from '@fortawesome/pro-light-svg-icons/faAt'
import { faBadgePercent as falBadgePercent } from '@fortawesome/pro-light-svg-icons/faBadgePercent'
import { faBan as falBan } from '@fortawesome/pro-light-svg-icons/faBan'
import { faBars as falBars } from '@fortawesome/pro-light-svg-icons/faBars'
import { faBed as falBed } from '@fortawesome/pro-light-svg-icons/faBed'
import { faBell as falBell } from '@fortawesome/pro-light-svg-icons/faBell'
import { faCartShopping as falCartShopping } from '@fortawesome/pro-light-svg-icons/faCartShopping'
import { faCheck as falCheck } from '@fortawesome/pro-light-svg-icons/faCheck'
import { faCheckCircle as falCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle'
import { faChevronDown as falChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown'
import { faChevronLeft as falChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft'
import { faChevronRight as falChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight'
import { faChevronUp as falChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp'
import { faCircle as falCircle } from '@fortawesome/pro-light-svg-icons/faCircle'
import { faCircleCheck as falCircleCheck } from '@fortawesome/pro-light-svg-icons/faCircleCheck'
import { faCircleInfo as falCircleInfo } from '@fortawesome/pro-light-svg-icons/faCircleInfo'
import { faCircleQuestion as falCircleQuestion } from '@fortawesome/pro-light-svg-icons/faCircleQuestion'
import { faCircleXmark as falCircleXmark } from '@fortawesome/pro-light-svg-icons/faCircleXmark'
import { faClockRotateLeft as falClockRotateLeft } from '@fortawesome/pro-light-svg-icons/faClockRotateLeft'
import { faComments as falComments } from '@fortawesome/pro-light-svg-icons/faComments'
import { faDownload as falDownload } from '@fortawesome/pro-light-svg-icons/faDownload'
import { faDumbbell as falDumbbell } from '@fortawesome/pro-light-svg-icons/faDumbbell'
import { faFaceSadTear as falFaceSadTear } from '@fortawesome/pro-light-svg-icons/faFaceSadTear'
import { faExclamationCircle as falExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle'
import { faHospitalAlt as falHospitalAlt } from '@fortawesome/pro-light-svg-icons/faHospitalAlt'
import { faListCheck as falListCheck } from '@fortawesome/pro-light-svg-icons/faListCheck'
import { faLockKeyhole as falLockKeyhole } from '@fortawesome/pro-light-svg-icons/faLockKeyhole'
import { faFileCheck as falFileCheck } from '@fortawesome/pro-light-svg-icons/faFileCheck'
import { faFilePdf as falFilePdf } from '@fortawesome/pro-light-svg-icons/faFilePdf'
import { faSpinnerThird as falSpinnerThird } from '@fortawesome/pro-light-svg-icons/faSpinnerThird'
import { faSquare as falSquare } from '@fortawesome/pro-light-svg-icons/faSquare'
import { faSquareCheck as falSquareCheck } from '@fortawesome/pro-light-svg-icons/faSquareCheck'
import { faTimer as falTimer } from '@fortawesome/pro-light-svg-icons/faTimer'
import { faPaperclip as falPaperclip } from '@fortawesome/pro-light-svg-icons/faPaperclip'
import { faPaste as falPaste } from '@fortawesome/pro-light-svg-icons/faPaste'
import { faPen as falPen } from '@fortawesome/pro-light-svg-icons/faPen'
import { faHeadset as falHeadset } from '@fortawesome/pro-light-svg-icons/faHeadset'
import { faUserMd as falUserMd } from '@fortawesome/pro-light-svg-icons/faUserMd'
import { faXmark as falXmark } from '@fortawesome/pro-light-svg-icons/faXmark'
import { faRightFromBracket as falRightFromBracket } from '@fortawesome/pro-light-svg-icons/faRightFromBracket'
import { faUpload as falUpload } from '@fortawesome/pro-light-svg-icons/faUpload'
import { faUserHeadset as falUserHeadset } from '@fortawesome/pro-light-svg-icons/faUserHeadset'

library.add(
  falAngleDown,
  falAngleUp,
  falArrowLeft,
  falArrowRight,
  falArrowRotateLeft,
  falAt,
  falBadgePercent,
  falBan,
  falBars,
  falBed,
  falBell,
  falCartShopping,
  falCheck,
  falCheckCircle,
  falChevronDown,
  falChevronLeft,
  falChevronRight,
  falChevronUp,
  falCircle,
  falCircleCheck,
  falCircleInfo,
  falCircleQuestion,
  falCircleXmark,
  falClockRotateLeft,
  falComments,
  falDownload,
  falDumbbell,
  falExclamationCircle,
  falFaceSadTear,
  falFileCheck,
  falFilePdf,
  falHeadset,
  falHospitalAlt,
  falListCheck,
  falLockKeyhole,
  falPaperclip,
  falPaste,
  falPen,
  falRightFromBracket,
  falSpinnerThird,
  falSquare,
  falSquareCheck,
  falTimer,
  falUpload,
  falUserMd,
  falUserHeadset,
  falXmark
)

// Benefits Widget

import { faHospital as falHospital } from '@fortawesome/pro-light-svg-icons/faHospital'
import { faHouseMedical as falHouseMedical } from '@fortawesome/pro-light-svg-icons/faHouseMedical'
import { faHeadSideMedical as falHeadSideMedical } from '@fortawesome/pro-light-svg-icons/faHeadSideMedical'
import { faHeadSideBrain as falHeadSideBrain } from '@fortawesome/pro-light-svg-icons/faHeadSideBrain'
import { faGlobe as falGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe'
import { faKitMedical as falKitMedical } from '@fortawesome/pro-light-svg-icons/faKitMedical'
import { faBedPulse as falBedPulse } from '@fortawesome/pro-light-svg-icons/faBedPulse'
import { faSeedling as falSeedling } from '@fortawesome/pro-light-svg-icons/faSeedling'
import { faGlasses as falGlasses } from '@fortawesome/pro-light-svg-icons/faGlasses'
import { faUserNurse as falUserNurse } from '@fortawesome/pro-light-svg-icons/faUserNurse'
import { faVacuum as falVacuum } from '@fortawesome/pro-light-svg-icons/faVacuum'
import { faHouseHeart as falHouseHeart } from '@fortawesome/pro-light-svg-icons/faHouseHeart'
import { faSpa as falSpa } from '@fortawesome/pro-light-svg-icons/faSpa'
import { faPersonSwimming as falPersonSwimming } from '@fortawesome/pro-light-svg-icons/faPersonSwimming'
import { faHotTubPerson as falHotTubPerson } from '@fortawesome/pro-light-svg-icons/faHotTubPerson'
import { faCoins as falCoins } from '@fortawesome/pro-light-svg-icons/faCoins'
import { faShirt as falShirt } from '@fortawesome/pro-light-svg-icons/faShirt'
import { faBandage as falBandage } from '@fortawesome/pro-light-svg-icons/faBandage'
import { faStethoscope as falStethoscope } from '@fortawesome/pro-light-svg-icons/faStethoscope'
import { faPills as falPills } from '@fortawesome/pro-light-svg-icons/faPills'
import { faUmbrellaSimple as falUmbrellaSimple } from '@fortawesome/pro-light-svg-icons/faUmbrellaSimple'
import { faPersonPregnant as falPersonPregnant } from '@fortawesome/pro-light-svg-icons/faPersonPregnant'
import { faHospitalUser as falHospitalUser } from '@fortawesome/pro-light-svg-icons/faHospitalUser'
import { faSquarePlus as falSquarePlus } from '@fortawesome/pro-light-svg-icons/faSquarePlus'
import { faLifeRing as falLifeRing } from '@fortawesome/pro-light-svg-icons/faLifeRing'
import { faTruckMedical as falTruckMedical } from '@fortawesome/pro-light-svg-icons/faTruckMedical'
import { faHelicopter as falHelicopter } from '@fortawesome/pro-light-svg-icons/faHelicopter'
import { faCarSide as falCarSide } from '@fortawesome/pro-light-svg-icons/faCarSide'
import { faTooth as falTooth } from '@fortawesome/pro-light-svg-icons/faTooth'
import { faSuitcaseMedical as falSuitcaseMedical } from '@fortawesome/pro-light-svg-icons/faSuitcaseMedical'
import { faTeethOpen as falTeethOpen } from '@fortawesome/pro-light-svg-icons/faTeethOpen'
import { faToothbrush as falToothbrush } from '@fortawesome/pro-light-svg-icons/faToothbrush'
import { faFamily as falFamily } from '@fortawesome/pro-light-svg-icons/faFamily'
import { faCandleHolder as falCandleHolder } from '@fortawesome/pro-light-svg-icons/faCandleHolder'
import { faPhone as falPhone } from '@fortawesome/pro-light-svg-icons/faPhone'
import { faPercent as falPercent } from '@fortawesome/pro-light-svg-icons/faPercent'
import { faEnvelopeOpenDollar as falEnvelopeOpenDollar } from '@fortawesome/pro-light-svg-icons/faEnvelopeOpenDollar'
import { faFilesMedical as falFilesMedical } from '@fortawesome/pro-light-svg-icons/faFilesMedical'
import { faNotesMedical as falNotesMedical } from '@fortawesome/pro-light-svg-icons/faNotesMedical'
import { faDna as falDna } from '@fortawesome/pro-light-svg-icons/faDna'
import { faMicroscope as falMicroscope } from '@fortawesome/pro-light-svg-icons/faMicroscope'
import { faUser as falUser } from '@fortawesome/pro-light-svg-icons/faUser'
import { faBaby as falBaby } from '@fortawesome/pro-light-svg-icons/faBaby'
import { faChild as falChild } from '@fortawesome/pro-light-svg-icons/faChild'
import { faCircleDollar as falCircleDollar } from '@fortawesome/pro-light-svg-icons/faCircleDollar'

library.add(
  falHospital,
  falHouseMedical,
  falHeadSideMedical,
  falHeadSideBrain,
  falGlobe,
  falKitMedical,
  falBedPulse,
  falSeedling,
  falGlasses,
  falUserNurse,
  falVacuum,
  falHouseHeart,
  falSpa,
  falPersonSwimming,
  falHotTubPerson,
  falCoins,
  falShirt,
  falBandage,
  falStethoscope,
  falPills,
  falUmbrellaSimple,
  falPersonPregnant,
  falHospitalUser,
  falSquarePlus,
  falLifeRing,
  falTruckMedical,
  falHelicopter,
  falCarSide,
  falTooth,
  falSuitcaseMedical,
  falTeethOpen,
  falToothbrush,
  falFamily,
  falCandleHolder,
  falPhone,
  falPercent,
  falEnvelopeOpenDollar,
  falFilesMedical,
  falNotesMedical,
  falDna,
  falMicroscope,
  falUser,
  falBaby,
  falChild,
  falCircleDollar
)

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default FontAwesomeIcon

export const ART = {
  fad: 'duo',
  far: 'regular',
  fat: 'thin',
  fal: 'light',
  fas: 'solid',
}
