import { isRequired, MissingParameterError } from '@/utils/CustomErrors'

import config from '@/config'

import useBackend from '@/hooks/useBackend'

function contract() {
  // HOOKS
  const { setup, doGet } = useBackend()

  // INIT
  setup('/ovpv3/public', config.microserviceBaseUrl)

  // METHODS

  /**
   * getContract sends a GET-request to /baskets/{basketId}/contracts/{contractNumber}
   * to get the existing contract of a person
   * @param {string} basketId
   * @param {string} contractNumber
   * @param {object} params
   * @param {string} params.language
   * @returns {Promise<object>}
   */
  async function getContract(basketId = isRequired('basketId'), contractNumber = isRequired('contractNumber'), params) {
    if (!params.language) throw new MissingParameterError('language')

    const response = await doGet({ url: `/baskets/${basketId}/contracts/${contractNumber}`, params })
    return response.data
  }

  return {
    getContract,
  }
}

export default contract()
