function compare(a, b) {
  if (a < b) return -1
  if (a > b) return 1
  return 0
}

export const SortUtil = {
  sortByOrder: (a, b) => compare(a.order, b.order),
  sortReverseByOrder: (a, b) => compare(b.order, a.order),
  sortByParam: param => (a, b) => {
    switch (typeof param) {
      case 'function':
        return compare(param(a), param(b))
      case 'String':
      default:
        return compare(a[param], b[param])
    }
  },
  sortReverseByParam: param => (a, b) => {
    switch (typeof param) {
      case 'function':
        return compare(param(b), param(a))
      case 'String':
      default:
        return compare(b[param], a[param])
    }
  },
}
