<template>
  <transition-group
    class="snackbar"
    name="snackbar"
    tag="div"
  >
    <template
      v-for="snack in snacks"
      :key="snack.id"
    >
      <div
        class="notification"
        :class="getNotificationClasses(snack)"
      >
        <span
          class="notification__text body-2"
          v-html="snack.text"
        />
        <basic-text-button
          v-if="snack.action && !isAide"
          g
          class="notification__action"
          :label="snack.action.label"
          @click="handleActionClick(snack.action.callback, snack.id)"
        />
        <basic-close-button
          class="notification__close"
          @click="removeNotification(snack.id)"
        />
      </div>
    </template>
  </transition-group>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import useApplication from '@/hooks/useApplication'
import useSnackbar from '@/hooks/useSnackbar'

import BasicTextButton from '@/components/Basic/TextButton'
import BasicCloseButton from '@/components/Basic/CloseButton'

// HOOKS
const { isWww } = useApplication()
const route = !isWww.value ? useRoute() : false
const { snacks, removeNotification } = useSnackbar()

// COMPUTED
const isAide = computed(() => {
  return route?.meta?.aide
})

// METHODS
function getNotificationClasses(notification) {
  return {
    [`notification--${notification.type}`]: true,
  }
}

function handleActionClick(callback, snackId) {
  if (!callback) return
  removeNotification(snackId)
  callback()
}
</script>

<style name="animations" scoped>
.snackbar-enter-active,
.snackbar-leave-active {
  transition: transform 0.3s cubic-bezier(0.65, 0, 0.35, 1);
}

.snackbar-enter-from,
.snackbar-leave-to {
  transform: translateY(200px);
}
</style>

<style scoped>
.snackbar {
  position: fixed;
  bottom: var(--fixed-spacing-fix-08);
  left: var(--dotcom-responsive-offset-responsive);
  right: var(--dotcom-responsive-offset-responsive);
  cursor: default;
  z-index: var(--dvp-stack-level-popup-notification);
  display: flex;
  flex-direction: column-reverse;
  gap: var(--fixed-spacing-fix-03);
}

.notification,
.notification--info {
  --notification-color-background: var(--surface-ext-b-medium);
  --notification-color-actions: var(--Interaction-States-enabled-extended);
  --notification-color-actions-hovered: var(--Interaction-States-hovered-extended-a);
  --notification-color-actions-active: var(--Interaction-States-pressed-extended-a);
  --notification-color-text: var(--on-surface);
}

.notification--warning {
  --notification-color-background: var(--warning-container);
  --notification-color-actions: var(--Interaction-States-enabled-on-warning-container);
  --notification-color-actions-hovered: var(--Interaction-States-hovered-on-warning-container);
  --notification-color-actions-active: var(--Interaction-States-pressed-on-warning-container);
  --notification-color-text: var(--on-warning-container);
}

.notification--error {
  --notification-color-background: var(--error-container);
  --notification-color-actions: var(--Interaction-States-enabled-on-error-container);
  --notification-color-actions-hovered: var(--Interaction-States-hovered-on-error-container);
  --notification-color-actions-active: var(--Interaction-States-pressed-on-error-container);
  --notification-color-text: var(--on-error-container);
}

.notification {
  background-color: var(--notification-color-background);
  box-shadow: var(--elevation-level-3);
  padding: var(--fixed-spacing-fix-04);
  display: grid;
  grid-template-columns: 1fr min-content min-content;
  column-gap: var(--fixed-spacing-fix-03);
  grid-template-areas:
    'text text close'
    '. action action';
  align-items: flex-start;
  border-radius: var(--radius-border-radius-02);
}

.notification__text {
  grid-area: text;
  color: var(--notification-color-text);
}

.notification__close {
  grid-area: close;
  justify-self: flex-end;

  &:deep(.icon) {
    color: var(--notification-color-actions);
  }

  &:hover:deep(.icon) {
    color: var(--notification-color-actions-hovered);
  }

  &:active:deep(.icon) {
    color: var(--notification-color-actions-active);
  }
}

.notification__action {
  grid-area: action;
  margin: var(--fixed-spacing-fix-04) 0 0;
  &.text-button {
    color: var(--notification-color-actions);
  }

  &:hover {
    &.text-button {
      color: var(--notification-color-actions-hovered);
    }

    &:deep(.text-button__overlay) {
      background-color: transparent;
      border-color: var(--notification-color-actions-hovered);
      border-width: 2px;
    }
  }

  &:active {
    &.text-button {
      color: var(--notification-color-actions-active);
    }

    &:deep(.text-button__overlay) {
      background-color: transparent;
      border-color: var(--notification-color-actions-active);
      border-width: 1px;
    }
  }
}

@media (--v-medium) {
  .snackbar {
    left: 50%;
    right: 0;
    transform: translateX(-50%);
    width: 576px;
  }

  .notification {
    padding: var(--fixed-spacing-fix-05) var(--fixed-spacing-fix-04);
    grid-template-areas: 'text action close';
    column-gap: var(--fixed-spacing-fix-04);
  }

  .notification__action {
    margin: 0 var(--fixed-spacing-fix-02) 0 0;
  }
}
</style>
