export const EVENT_APPOINTMENT_MODAL_OPEN = 'ovp-appointment_modal_open'
export const EVENT_CONFIRMATION_MODAL_OPEN = 'ovp-confirmation_modal_open'
export const EVENT_MEDICAL_OFFICE_MODAL_OPEN = 'ovp-medical-search_modal_open'
export const EVENT_SHOW_PRODUCT_SUGGESTION = 'ovp-show-product-suggestion'

export const EVENT_CONTRACT_OWNER_CHANGED = 'ovp-contract_owner_changed'
export const EVENT_CUSTOMER_PORTAL_CHANGED = 'ovp-customer_portal_changed'

export const EVENT_DAP_SAVING = 'ovp-dap_saving' // to be removed
export const EVENT_PERSONS_FORM_DISPLAYED = 'ovp-persons_form_displayed'

export const EVENT_CANCEL_DEBOUNCED_SAVING = 'ovp-cancel-debounced-saving' // to be removed
export const EVENT_FLUSH_DEBOUNCED_SAVING = 'ovp-flush-debounced-saving' // to be removed

export const EVENT_CANCELLATION_MAIN_ANSWER_CHANGED = 'ovp-cancellation-main-answer-changed'

export const EVENT_DOTCOM_SURVEY_WEBSITE = 'ovp-survey-website'
export const CUSTOM_EVENT_SURVEY_TRIGGER = 'sanitas_survey-trigger_event'

export const EVENT_LOGIN = {
  OPEN: 'ovp-login_open',
  OPENED: 'ovp-login_opened',
}

export const EVENT_OVP = {
  APPLICATION_SUBMITTED: 'ovp-application_submitted',
  BUSY: 'ovp_busy',
  ENTERED: 'ovp-entered',
  ERROR: 'ovp-error',
  NUDGE_CLOSE: 'ovp-nudge_close',
  REENTRY_LOADED: 'ovp-reentry_loaded',
  SYNC_EXECUTED: 'ovp-sync_executed',
  DEFAULT_BASKET_CREATED: 'ovp-default_basket_created',
}

export const EVENT_AIDE = {
  DEFAULT_OFFER: 'alva-default-offer',
  EXIT: 'alva-exit',
  MENU_OPEN: 'alva-menu-open',
  RESTART: 'alva-restart-open',
}

export const EVENT_BASKET = {
  CREATED: 'ovp-basket_created',
  OPEN: 'ovp-basket_open',
  CLOSE: 'ovp-basket_close',
  UPDATED: 'ovp-basket_updated',
  WIDGET_CLOSE: 'ovp-basket_widget_close',
  OPEN_SCREEN_SAVE_MODAL: 'ovp-open_basket_screen_save',
  SAVED: 'ovp-basket_saved',
  DIFF_OPENED: 'ovp-basket-diff_opened',
  WIDGET_OPEN_MODAL: 'ovp-basket_widget_open_modal',
  TRACK_AFTER_UPDATE: 'ovp-track-after-basket-update',
  CLEAR_AFTER_UPDATE: 'ovp-clear-after-basket-update',
}

export const EVENT_CHAT = {
  ACTION: 'sanitas-chat-action', // Is an uri param action from emails
  LOADED: 'ovp-chat_loaded',
  NUDGING: 'ovp-chat_trigger_nudging',
  OPEN: 'ovp-chat_open',
  CLOSE: 'ovp-chat_close',
}

export const EVENT_CLIPPY = {
  ADD: 'ovp-clippy_add',
  HIDE: 'ovp-clippy_hide',
}

export const EVENT_FAB = {
  OPEN: 'OVPFabOpen',
  OPENED: 'OVPFabOpenened',
}

export const EVENT_FORM = {
  INIT: 'OvpFormStarted',
  INTERACTED: 'OvpFormInteracted',
  CANCELED: 'OvpFormCancelled',
  ERROR: 'OvpFormError',
  SUBMITTED: 'OvpFormSubmitted',
}

export const EVENT_LANGUAGE = {
  LOADED: 'ovp-language_loaded', // initial language load (used for dotcom widgets)
}

export const EVENT_MODAL = {
  OPEN: 'ovp-open_modal',
  UPDATE: 'ovp-update_modal',
  BEFORE_CLOSING: 'ovp-before-closing_modal',
  CLOSE: 'ovp-close_modal',
}

export const EVENT_ONBOARDING = {
  OPEN: 'ovp-onboarding_open',
}

export const EVENT_SESSION = {
  CLEAR: 'ovp-session_cleared',
  RESUME: 'ovp-session_resume',
  START: 'ovp-session_start',
  UPDATE: 'ovp-session_updated',
}

export const EVENT_TRANSFER_DOCTOR_FROM_OTHER = {
  APPLIED: 'ovp-doctor_transfer_applied',
  DISPLAYED: 'ovp-doctor_transfer_displayed',
}

export const EVENT_TRACKING = {
  ENTERED: 'ovp-tracking_entered',
  ROUTE_CHANGE: 'ovp-tracking_route_change',
}

export const EVENT_PRODUCT = {
  ADDED: 'ovp-product_added',
  REMOVED: 'ovp-product_removed',
  CHANGED: 'ovp-product_changed',
  ACCIDENT_CHANGED: 'ovp-product_accident_changed',
  FRANCHISE_CHANGED: 'ovp-product_franchise_changed',
  DURATION_CHANGED: 'ovp-product_duration_changed',
  DETAIL_OPEN: 'ovp-product_detail_open',
  STRUCTURE_SET: 'ovp-product_structure_set',

  SUGGESTION_DISPLAYED: 'ovp-product-suggestion_displayed',
  REPLACE_OPEN: 'ovp-product_replace_open',
  REPLACE_DISPLAYED: 'ovp-product-replace_displayed',
}

export const SOURCE = {
  AIDE_ENTRY: 'AlvaEntry',
  AIDE_NUDGE: 'AlvaNudge',
  AIDE_EXIT: 'AlvaExit',

  AIDE_ACCIDENT: 'AlvaAccident',
  AIDE_BASKET: 'AlvaBasket',
  AIDE_FRANCHISE: 'AlvaFranchise',
  AIDE_PRODUCT_HOSP: 'AlvaProductHOSP',
  AIDE_PRODUCT_KVG: 'AlvaProductKVG',
  AIDE_PRODUCT_VVG: 'AlvaProductVVG',

  BACKEND: 'BACKEND',
  BASKET: 'Basket',
  BASKET_WIDGET: 'BasketWidget',
  CONFIGURATOR: 'Configurator',
  ENTRY_WIDGET: 'ComOvpEntry',
  INCENTIVE: 'Incentive',
  ONBOARDING: 'Onboarding',
  PARTNER_WIDGET: 'PartnerWidget',
  PRICE_WIDGET: 'PriceWidget',
  PRODUCT_WIDGET: 'ProductWidget',
  RECOMMENDATION: 'Recommendation',
  UPSELLING: 'Upsell',
  SAVE_NUDGE: 'SAVE_NUDGE',
  PRODUCT_BENEFITS: 'ProductBenefits',
  PRODUCT_COMPARISON: 'ProductComparison',
}

export const EVENT_PARTNER_WIDGET = {
  DISPLAYED: 'ovp-partner-widget-displayed',
  GOTO_SANITAS: 'ovp-partner-widget-goto-sanitas',
  PAGE_VIEWED: 'ovp-partner-widget-page-viewed',
}

export const CUSTOM_EVENT_CHAT = {
  BASKET_UPDATED: 'sanitas_ovp-basket_updated',
  CONVERSATION_ENDED: 'sanitas_chat-enduser_conversation-ended',
  CONVERSATION_STARTED: 'sanitas_chat-enduser_conversation-started',
  LANGUAGE: 'sanitas_chat-enduser_language',
  LOADED: 'sanitas_chat-loaded',
  OPEN: 'sanitas_chat-enduser_open',
  TRIGGER_NUDGING: 'sanitas_chat-trigger_nudging',
  MINIMIZED: 'sanitas-chat_minimize',
  CLOSE: 'sanitas-chat_close',
}

export const EVENT_URL = {
  PREFILL: 'ovp-events_prefill',
  GOTO: 'ovp-events_goto',
}
