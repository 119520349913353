<template>
  <div
    class="loading"
    :class="{ 'loading--alternative': $props.alternative }"
  >
    <div class="loading--modern">
      <svg
        version="1.1"
        class="loading-icon"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 112.9 54.8"
        style="enable-background: new 0 0 112.9 54.8"
        xml:space="preserve"
      >
        <path
          id="logo_s_1"
          d="M10.2,43.6l-2.1-0.8c-1.5-0.5-2.4-0.9-2.4-1.7c0-0.7,0.6-1.1,1.8-1.1c1.6,0,4,0.5,5.4,0.8l0.7-4.2
  c-1.6-0.4-4.3-0.9-7-0.9c-3.7,0-6.6,2.1-6.6,5.9c0,3,1.7,4.3,4.8,5.4l1.8,0.7c1.6,0.6,2.1,0.8,2.1,1.6c0,1-1.2,1.1-2.1,1.1
  c-1.7,0-4.1-0.5-5.6-0.8l-0.8,4.3c1.6,0.4,4.4,0.8,6.8,0.8c4.9,0,7.4-2.2,7.4-5.6C14.5,47,13.7,44.9,10.2,43.6"
        />
        <path
          id="logo_a_1"
          d="M25.4,35.8c-2.6,0-5.3,0.3-7.7,0.8l0.7,4.2c1.6-0.3,3.7-0.6,5.1-0.6c2.5,0,3,0.7,3,2.3v0.7
  c-1,0-1.9-0.1-3-0.1c-4.1,0-7.2,2.3-7.2,6.4c0,3.8,2.6,5.3,5.5,5.3c2.1,0,3.7-1.1,5.1-2.1l0.5,1.8h5V43.1
  C32.4,37.4,29.2,35.8,25.4,35.8 M26.5,49.4c-0.9,0.6-1.9,0.9-2.8,0.9c-1,0-1.9-0.5-1.9-1.5c0-1.8,1.5-2.1,2.9-2.1
  c0.6,0,1.1,0,1.8,0.1V49.4L26.5,49.4z"
        />
        <path
          id="logo_n_1"
          d="M47.8,35.7c-3.1,0-5.1,1.5-6.6,2.5L40.6,36h-4.7v18.5h6v-12c0.7-0.7,2.1-1.4,3.2-1.4c1.2,0,1.9,0.4,1.9,2.1
  v11.3h6V42.1C53,37.5,51.1,35.7,47.8,35.7"
        />
        <path
          id="logo_i_1"
          d="M60.8,27.4c1.9,0,3.4,1.5,3.4,3.3c0,1.9-1.5,3.3-3.3,3.3c-1.9,0-3.3-1.5-3.3-3.3C57.6,28.9,59,27.4,60.8,27.4
   M55.7,40.3H58v14.2h6V36h-8.3V40.3z"
        />
        <path
          id="logo_t_1"
          d="M73.7,48.4v-8.1h3.7V36h-3.7v-5.1l-6,0.8v18.1c0,3.7,2.5,5,5.1,5c1.3,0,2.9-0.2,4.8-0.6v-4.3
  c-1,0.2-1.7,0.3-2.3,0.3C74.2,50.2,73.7,49.7,73.7,48.4"
        />
        <path
          id="logo_a_2"
          d="M88.7,35.8c-2.6,0-5.3,0.3-7.7,0.8l0.7,4.2c1.6-0.3,3.7-0.6,5.1-0.6c2.5,0,3,0.7,3,2.3v0.7
  c-1,0-1.9-0.1-2.9-0.1c-4.1,0-7.2,2.3-7.2,6.4c0,3.8,2.6,5.3,5.5,5.3c2.2,0,3.7-1.1,5.1-2.1l0.5,1.8h5V43.1
  C95.8,37.4,92.6,35.8,88.7,35.8 M89.8,49.4c-0.9,0.6-1.9,0.9-2.8,0.9c-1.1,0-1.9-0.5-1.9-1.5c0-1.8,1.5-2.1,2.9-2.1
  c0.6,0,1.2,0,1.8,0.1V49.4L89.8,49.4z"
        />
        <path
          id="logo_s_2"
          d="M108.7,43.6l-2.1-0.8c-1.5-0.6-2.4-0.9-2.4-1.7c0-0.7,0.6-1.1,1.8-1.1c1.6,0,4,0.5,5.4,0.8l0.7-4.2
  c-1.6-0.4-4.3-0.9-7-0.9c-3.7,0-6.6,2.1-6.6,5.9c0,3,1.7,4.3,4.8,5.4l1.8,0.7c1.6,0.6,2.1,0.8,2.1,1.6c0,1-1.2,1.1-2.1,1.1
  c-1.7,0-4.1-0.5-5.5-0.8l-0.8,4.3c1.6,0.4,4.4,0.8,6.8,0.8c4.9,0,7.4-2.2,7.4-5.6C112.9,47,112.1,44.9,108.7,43.6"
        />
      </svg>
    </div>

    <div class="loading--bars">
      <div class="loading__content">
        <div class="rect1" />
        <div class="rect2" />
        <div class="rect3" />
        <div class="rect4" />
        <div class="rect5" />
      </div>
    </div>

    <div class="loading-extra">
      <slot />
    </div>
  </div>
</template>

<script setup>
defineProps({
  alternative: {
    type: Boolean,
    default: false,
  },
})
</script>

<style name="mobile" scoped>
@keyframes jump-around {
  from {
    transform: translateY(-20%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}

.loading {
  &--modern {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
  }

  &--bars {
    display: none;
  }

  &--alternative {
    .loading--modern {
      display: none;
    }

    .loading--bars {
      text-align: center;
      display: flex;
      flex-direction: column;
    }
  }

  &-icon {
    align-self: center;
    width: 200px;
    fill: var(--surface-dim);

    path {
      animation: jump-around 1s infinite alternate-reverse;

      &#logo_a_1 {
        animation-delay: 0.25s;
      }

      &#logo_n_1 {
        animation-delay: 0.5s;
      }

      &#logo_i_1 {
        animation-delay: 0.75s;
      }

      &#logo_t_1 {
        animation-delay: 1s;
      }

      &#logo_a_2 {
        animation-delay: 1.25s;
      }

      &#logo_s_2 {
        animation-delay: 1.5s;
      }
    }
  }

  &-extra {
    margin-top: 5px;
    color: var(--c-primary-neutral-1);
    text-align: center;
  }

  .loading__content {
    margin: 10px auto;
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px;

    & > div {
      background-color: var(--c-primary-color-2);
      height: 100%;
      width: 6px;
      display: inline-block;

      animation: sk-stretchdelay 1.2s infinite ease-in-out;
    }

    .rect2 {
      animation-delay: -1.1s;
    }

    .rect3 {
      animation-delay: -1s;
    }

    .rect4 {
      animation-delay: -0.9s;
    }

    .rect5 {
      animation-delay: -0.8s;
    }
  }
}
</style>
