<template>
  <!-- prettier-ignore -->
  <div
    class="input"
    :class="classes"
  >
    <div
      ref="inputTriggerRef"
      class="input__container"
    >
      <div class="input__wrapper">
        <slot name="leading"></slot>
        <label
          class="input__content"
        >
          <span
            class="input__label"
            v-html="autoLabel"
          />
          <slot name="input__leading"></slot>
          <input
            ref="inputRef"
            v-model="model"
            v-maska="$props.mask"
            class="input__text outline"
            data-lpignore="true"
            data-1p-ignore
            :maxlength="$props.maxlength"
            :name="$props.name"
            :placeholder="$props.placeholder"
            :readonly="$props.readonly"
            :disabled="$props.disabled"
            type="text"
            @blur="handleBlur"
            @focus="handleFocus"
            @input="event => $emit('input', event)"
            @keydown="event => $emit('keydown', event)"
            @paste="event => $emit('paste', event)"
          />
        </label>
        <template v-if="showInputIcon">
          <the-icon
            v-if="showClearIcon"
            art="solid"
            class="clear-icon"
            name="circle-xmark"
            @mousedown.prevent="clear"
          />
          <the-icon
            v-else-if="isInvalid"
            art="solid"
            class="invalid-icon"
            name="circle-exclamation"
          />
          <the-icon
            v-else-if="$props.hasIconValidIndicator && isValid"
            art="solid"
            class="valid-icon"
            name="circle-check"
          />
        </template>
        <slot name="trailing"></slot>
      </div>
    </div>
    <div class="input__supporting-text caption-regular">
      <span v-text="autoHint" />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { vMaska } from 'maska/vue'

import useI18n from '@/hooks/useI18n'

// HOOKS
const { t } = useI18n()

// INIT
defineOptions({
  inheritAttrs: false,
})

const emit = defineEmits(['blur', 'clear', 'focus', 'keydown', 'input', 'paste'])
const model = defineModel({
  type: [String, Number],
  set(value) {
    if (value === '') return null

    return value
  },
})

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },

  hasIcons: {
    type: Boolean,
    default: true,
  },

  hasIconValidIndicator: {
    type: Boolean,
    default: false,
  },

  label: {
    type: String,
    default: null,
  },

  manualValidation: {
    type: Boolean,
    default: false,
  },

  mask: {
    type: [String, Array, Boolean, Object],
    default: false,
  },

  maxlength: {
    type: Number,
    default: 255,
  },

  name: {
    type: String,
    required: true,
  },

  placeholder: {
    type: String,
    default: undefined,
  },

  readonly: {
    type: Boolean,
    default: false,
  },

  size: {
    validator(value) {
      return ['sm', 'md'].includes(value)
    },
    default: 'sm',
  },

  supportingText: {
    type: String,
    default: undefined,
  },

  v: {
    type: Object,
    default: () => {},
  },
})

// DATA
const isFocused = ref(false)
const inputRef = ref(null)
const inputTriggerRef = ref(null) // Used as trigger element to position corresponding dropdown-menus

// COMPUTED
const classes = computed(() => {
  return {
    [`input--${props.size}`]: true,
    'input--focus': isFocused.value,
    'input--empty': isEmpty.value,
    'input--success': props.hasIconValidIndicator && isValid.value,
    'input--error': isInvalid.value,
    'input--has-clear': showClearIcon,
    'input--disabled': isDisabled.value,
  }
})

const autoLabel = computed(() => {
  let str = props.label
  if (isRequired.value) {
    str += ' *'
  }

  return str
})

const autoHint = computed(() => {
  if (isInvalid.value) {
    const error = props.v?.$errors[0]?.$validator
    if (error) return t(`form.error.${error}`)
  }
  return props.supportingText
})

const isDisabled = computed(() => {
  return props.disabled || props.readonly
})

const isEmpty = computed(() => {
  return model.value === undefined || model.value === null || model.value.length === 0
})

const isInvalid = computed(() => {
  return props.v?.$dirty && props.v?.$invalid
})

const isRequired = computed(() => {
  return !!props.v?.required
})

const isValid = computed(() => {
  return props.v?.$dirty && !props.v?.$invalid
})

const showClearIcon = computed(() => {
  return model.value && isFocused.value && !props.readonly
})

const showInputIcon = computed(() => {
  return props.hasIcons && (showClearIcon.value || isInvalid.value || (props.hasIconValidIndicator && isValid.value))
})

// METHODS
function clear() {
  model.value = null
  emit('clear')
}

function handleBlur() {
  inputRef.value?.classList.remove('has-outline')

  if (!props.manualValidation) props.v?.$validate()
  isFocused.value = false

  emit('blur', model.value)
}

function handleFocus(event) {
  inputRef.value?.select()
  isFocused.value = true

  emit('focus', event)
}

// EPILOGUE
defineExpose({
  handleBlur,
  inputRef,
  inputTriggerRef,
})
</script>

<style>
:root,
.surface-default {
  --Input-fields-input-on-alternative-surface-fill: var(--surface);
  --Input-fields-input-on-default-surface-fill: var(--surface-ext-a);
  --Input-fields-input-on-Inverted-surface-fill: var(--primary-container-low);
  --Input-fields-input-on-Inverted-surface-active-indicator: var(--on-inverse-surface);
  --Input-fields-input-border-radius: var(--radius-border-radius-02);
  --Input-fields-input-active-indicator-spacing: var(--fixed-spacing-fix-04);

  --dvp-input-c-background: var(--Input-fields-input-on-default-surface-fill);
  --dvp-input-c-default-active-indicator: var(--on-surface);

  --dvp-input-c-background-disabled: var(--Interaction-States-disabled-default);
  --dvp-input-c-label-disabled: var(--on-surface);

  --dvp-input-c-outline: var(--on-surface);
}

.surface-alternative {
  --dvp-input-c-background: var(--Input-fields-input-on-alternative-surface-fill);
  --dvp-input-c-background-disabled: var(--Interaction-States-disabled-extended);
  --dvp-input-c-label-disabled: var(--on-surface);

  --dvp-input-c-outline: var(--on-surface);
}

.surface-inverted {
  --dvp-input-c-background: var(--Input-fields-input-on-Inverted-surface-fill);
  --dvp-input-c-default-active-indicator: var(--Input-fields-input-on-Inverted-surface-active-indicator);
  --dvp-input-c-background-disabled: var(--Interaction-States-disabled-inverse);
  --dvp-input-c-label-disabled: var(--Interaction-States-disabled-inverse-label);

  --dvp-input-c-outline: var(--on-inverse-surface-variant);
}
</style>

<style scoped>
.input {
  --dvp-input-c-label-text: var(--on-surface-medium);
  --dvp-input-c-active-indicator: var(--dvp-input-c-default-active-indicator);
  --dvp-input-c-supporting-text: var(--on-surface-medium);

  display: inline-block;
  width: 100%;
  margin: 0 0 var(--fixed-spacing-fix-04);
}

/* Valid input */
.input--success {
  --dvp-input-c-label-text: var(--on-surface-medium);
  --dvp-input-c-active-indicator: var(--secondary);
  --dvp-input-c-supporting-text: var(--on-surface-medium);
}

/* Input error */
.input--error {
  --dvp-input-c-label-text: var(--error);
  --dvp-input-c-active-indicator: var(--error);
  --dvp-input-c-supporting-text: var(--error);
}

/* Small sized input */
.input,
.input--sm {
  --input-height: var(--spacing-san-spacing-12);
  --input-active-indicator-height: 2px;
  --input-label-top-spacing: 11px;
}

/* Medium sized input */
.input--md {
  --input-label-top-spacing: 6px;
}

/* Disabled input */
.input--disabled {
  pointer-events: none;
}

.input--disabled .input__container {
  background-color: var(--dvp-input-c-background-disabled);
}

.input--disabled .input__label,
.input--disabled .input__text,
.input--disabled .input__wrapper :deep(.icon),
.input--disabled .trailing__icon :deep(.icon),
.input--disabled .input__supporting-text {
  color: var(--dvp-input-c-label-disabled);
}

/* Active (focused) input */
.input--focus {
  .input__wrapper {
    box-shadow: inset 0 -2px 0 var(--dvp-input-c-active-indicator);
  }
}

/* Active (focused) input without success or error state */
.input--focus:not(.input--success):not(.input--error) {
  --dvp-input-c-label-text: var(--on-surface);
}

/* Focused input (also triggers when user clicks into input-field) */
.input:has(.has-outline):has(.input__text:focus-visible) .input__container {
  outline: 2px solid var(--dvp-input-c-outline);
  outline-offset: var(--fixed-spacing-fix-01);
  border-radius: var(--fixed-border-radius-fix-02);
}

/* Set input text size and label size (if input empty, not focused and there's no placholder) */
.input--sm .input__text,
.input--sm.input--empty:not(.input--focus) .input__content:has(.input__text:not(:placeholder-shown)) > .input__label {
  font-size: var(--font-size-regular-md);
  letter-spacing: var(--letter-spacing-regular-md);
  line-height: var(--line-height-regular-md);
}

.input--md .input__text,
.input--md.input--empty:not(.input--focus) .input__content:has(.input__text:not(:placeholder-shown)) > .input__label {
  font-size: var(--font-size-regular-xl);
  letter-spacing: var(--letter-spacing-regular-xl);
  line-height: var(--line-height-regular-xl);
}

/* Vertically position label (if input empty, not focused and there's no placeholder) */
.input--empty:not(.input--focus) .input__content:has(.input__text:not(:placeholder-shown)) > .input__label {
  top: var(--input-label-top-spacing);
}

.input--empty:not(.input--focus) .input__content:has(.input__text:not(:placeholder-shown)) .input__text {
  opacity: 0;
}

.input__container {
  height: var(--spacing-san-spacing-12);
  background-color: var(--dvp-input-c-background);
  border-radius: var(--Input-fields-input-border-radius);
  padding: 0 var(--fixed-spacing-fix-04);
}

.input__wrapper {
  font-size: var(--font-size-regular-md);
  display: flex;
  align-items: center;
  gap: var(--fixed-spacing-fix-04);
  height: 100%;
  box-shadow: inset 0 -1px 0 var(--dvp-input-c-active-indicator);
}

.input__content {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 100%;
  flex-grow: 1;
}

.input__wrapper :deep(.icon) {
  font-size: var(--font-size-regular-md);
  color: var(--on-surface-medium);
}

.valid-icon.icon {
  color: var(--secondary);
}

.invalid-icon.icon {
  color: var(--error);
}

.input__label {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: absolute;
  top: 0;
  width: 100%;
  font-size: var(--font-size-regular-xs);
  line-height: var(--line-height-regular-xs);
  letter-spacing: var(--letter-spacing-regular-xs);
  color: var(--dvp-input-c-label-text);
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  cursor: text;
}

.input__text {
  margin: 0 0 var(--input-active-indicator-height);
  padding: 0;
  border: none;
  opacity: 1;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  background-color: inherit;
  color: var(--on-surface);
  flex-grow: 1;

  &::placeholder {
    color: var(--on-surface-medium);
  }
}

.input__supporting-text {
  --input-indent-supporting-text: var(--fixed-spacing-fix-04);

  color: var(--dvp-input-c-supporting-text);
  margin: 0 0 0 var(--input-indent-supporting-text);
  height: var(--line-height-regular-xs);

  & span {
    display: inline-block;
    width: calc(100% - (2 * var(--input-indent-supporting-text)));
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
