export class MissingParameterError extends Error {
  constructor(paramName) {
    super(`parameter missing: ${paramName}`)
  }
}

export class NotFoundError extends Error {
  constructor(key, value) {
    super(`not found: ${key}: ${value}`)
  }
}

/*
 * If this error happens, it was a programmatic error and is not recoverable!
 * Thrown in case there must be a next route but none was found.
 */
export class RouteNotFoundError extends Error {
  constructor(component) {
    super(`no next route found in component ${component}`)
  }
}

export const isRequired = paramName => {
  throw new MissingParameterError(paramName)
}
