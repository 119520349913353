<template>
  <!-- prettier-ignore -->
  <div
    class="message"
    :class="classes"
    >
    <div class="message__container">
      <div class="message__header">
        <div class="message__title">
          <div class="message__icon">
            <the-icon
              art="regular"
              :name="currentIcon"
            />
          </div>
          <div
            class="title-2"
            v-html="title"
          />
        </div>
        <basic-close-button
          v-if="$props.closeable"
          class="message__close"
          @click="hide"
        />
      </div>
      <div
        v-if="$props.content || slots.default"
        class="message__content"
      >
        <slot>
          <span
            class="body-2"
            v-html="$props.content"
          />
        </slot>
      </div>
      <div
        v-if="$props.actions.length"
        class="message__actions"
      >
        <template
          v-for="action in $props.actions"
          :key="action"
        >
          <basic-text-button
            class="message__action"
            :label="action.label"
            @click="action.callback"
          />
        </template>

      </div>
    </div>
  </div>
</template>

<script setup>
/**
 * FIGMA https://www.figma.com/file/3r1w8rChrH97KwxTcUblDL/EP-Basic-Library?node-id=8416%3A18773&mode=dev:
 * NOTES:
 * KNOWN ISSUES:
 */
import { computed, ref, useSlots } from 'vue'

import { logWarning } from '@/helpers/Logger'
import useI18n from '@/hooks/useI18n'

import BasicCloseButton from '@/components/Basic/CloseButton'
import BasicTextButton from '@/components/Basic/TextButton'
import TheIcon from '@/components/Container/TheIcon'

import { NOTIFICATION } from '@/config/constants'

// HOOKS
const { t } = useI18n()
const slots = useSlots()

// INIT
const props = defineProps({
  actions: {
    type: Array,
    default: () => [],
    validator(val) {
      const isValidObj = val.every(obj => Object.hasOwn(obj, 'label') && Object.hasOwn(obj, 'callback'))
      if (!isValidObj) {
        logWarning('[Vue warn]: Invalid prop: actions should have the following properties: {label, callback}')
        return false
      }
      return true
    },
  },

  closeable: {
    type: Boolean,
    default: true,
  },

  content: {
    type: String,
    default: null,
  },

  severity: {
    type: String,
    validator(value) {
      return [NOTIFICATION.SUCCESS, NOTIFICATION.INFO, NOTIFICATION.WARNING, NOTIFICATION.ERROR].includes(value)
    },
    default: NOTIFICATION.INFO,
  },

  titleLabel: {
    type: String,
    default: null,
  },
})

// DATA
const hidden = ref(false)

// METHODS
function hide() {
  hidden.value = true
}

// COMPUTED
const classes = computed(() => {
  return {
    [`message--${props.severity}`]: true,
    'message--hidden': hidden.value,
  }
})

const currentIcon = computed(() => {
  switch (props.severity) {
    case NOTIFICATION.ERROR:
      return 'circle-exclamation'
    case NOTIFICATION.WARNING:
      return 'circle-exclamation'
    case NOTIFICATION.SUCCESS:
      return 'circle-check'
    case NOTIFICATION.INFO:
    default:
      return 'circle-info'
  }
})

const title = computed(() => {
  if (props.titleLabel) {
    return props.titleLabel
  } else {
    return t(`message.${props.severity.toLowerCase()}`)
  }
})
</script>

<style scoped>
.message--info,
.message--success {
  --message-color-background: var(--surface-ext-b-medium);
  --message-color-actions: var(--Interaction-States-enabled-extended);
  --message-color-actions-hovered: var(--Interaction-States-hovered-extended-a);
  --message-color-actions-active: var(--Interaction-States-pressed-extended-a);
  --message-color-text: var(--on-surface);
}

.message--warning {
  --message-color-background: var(--warning-container);
  --message-color-actions: var(--Interaction-States-enabled-on-warning-container);
  --message-color-actions-hovered: var(--Interaction-States-hovered-on-warning-container);
  --message-color-actions-active: var(--Interaction-States-pressed-on-warning-container);
  --message-color-text: var(--on-warning-container);
}

.message--error {
  --message-color-background: var(--error-container);
  --message-color-actions: var(--Interaction-States-enabled-on-error-container);
  --message-color-actions-hovered: var(--Interaction-States-hovered-on-error-container);
  --message-color-actions-active: var(--Interaction-States-pressed-on-error-container);
  --message-color-text: var(--on-error-container);
}

.message--hidden {
  display: none;
}

.message {
  padding: var(--fixed-spacing-fix-04);
  background-color: var(--message-color-background);
  border-radius: var(--fixed-border-radius-fix-02);
}

.message__container {
  display: flex;
  flex-direction: column;
  gap: var(--fixed-spacing-fix-04);
}

.message__header {
  display: flex;
  justify-content: space-between;
  gap: var(--fixed-spacing-fix-06);
}

.message__title {
  display: flex;
  align-items: flex-start;
  gap: var(--fixed-spacing-fix-02);
  color: var(--message-color-text);
}

.message__close {
  :deep(.icon) {
    color: var(--message-color-actions);
  }

  &:hover:deep(.icon) {
    color: var(--message-color-actions-hovered);
  }

  &:active:deep(.icon) {
    color: var(--message-color-actions-active);
  }
}

.message__content {
  text-align: initial;
  color: var(--message-color-text);

  :deep(ul),
  :deep(ol) {
    padding: 0;
    margin: 0;
    list-style-position: inside;
  }
}

.message__actions {
  display: flex;
  justify-content: space-between;
  padding-top: var(--fixed-spacing-fix-04);
}

.message__action {
  &.text-button {
    color: var(--message-color-actions);
  }

  &:hover {
    &.text-button {
      color: var(--message-color-actions-hovered);
    }

    &:deep(.text-button__overlay) {
      background-color: transparent;
      border-color: var(--message-color-actions-hovered);
      border-width: 2px;
    }
  }

  &:active {
    &.text-button {
      color: var(--message-color-actions-active);
    }

    &:deep(.text-button__overlay) {
      background-color: transparent;
      border-color: var(--message-color-actions-active);
      border-width: 1px;
    }
  }
}

@media (--v-medium) {
  .message__container {
    gap: var(--fixed-spacing-fix-02);
  }
  .message__actions {
    justify-content: flex-end;
    gap: var(--fixed-spacing-fix-06);
  }
}
</style>
