import { computed, reactive, ref, watch } from 'vue'

import events$ from '@/services/Events'
import { isProductReloadNecessary } from '@/utils/BasketConfig'
import { filterSoftCloseProductsHaX, haXProducts } from '@/utils/HaX'
import { mapIdToProductId } from '@/utils/Product'
import { ReactivityUtil } from '@/utils/Reactivity'

import productAPI from '@/api/product'

import { EVENT_PRODUCT } from '@/config/events'

// DATA
const initialized = ref(false)

const store = reactive({
  categories: {},
  groups: {},
  products: {},
})

function productStructureStore() {
  // COMPUTED
  const categories = computed(() => {
    return store.categories
  })

  const groups = computed(() => {
    return store.groups
  })

  const products = computed(() => {
    const data = ReactivityUtil.clone(store.products)

    Object.keys(store.products).forEach(__productId => {
      mapIdToProductId(data, __productId)
    })

    return data
  })

  // METHODS

  function ready() {
    return new Promise(function (resolve) {
      if (initialized.value) resolve()

      const unwatch = watch(
        () => initialized.value,
        () => {
          unwatch()
          resolve()
        }
      )
    })
  }

  /**
   * initProducts loads productStructure, if products need to be loaded
   * caveat: also get's called on initial local basket-initialization, but cancels, because masterTreaty is missing
   *
   * @param {Basket} __basket
   * @param {Basket} originalBasket
   */
  async function installProductStructureOnUpdate(basket, originalBasket) {
    if (basket?.persons?.length === 0) return

    const __reloadNecessary = !!isProductReloadNecessary(basket, originalBasket)

    if ((__reloadNecessary || initialized.value === false) && basket.masterTreaty) {
      let structure = await productAPI.getProducts(basket)

      if (structure) {
        initialized.value = true
        setProducts(structure)
      }
    }
  }

  /**
   *  setProducts takes a productStructure and stores it in the local data
   *  @param {ProductStructure} payload
   */
  function setProducts(payload) {
    /*
     *  HaX for soft close:
     *  We filter out soft closed products (classic, family, jump)
     *  and store these filtered products in the original productData
     *  (as we no longer want to show these products to the users)
     *  But as we need the original (non-filtered) products for the
     *  existing customers to eventually replace the newly-selected vital
     *  product with the existing soft closed product, we store them
     *  in the haXProducts
     */
    const __payload = filterSoftCloseProductsHaX(ReactivityUtil.clone(payload))
    ReactivityUtil.reAssign(store, __payload)
    ReactivityUtil.reAssign(haXProducts, payload)

    events$.emit(EVENT_PRODUCT.STRUCTURE_SET, __payload)
  }

  return {
    categories,
    ready,
    initialized,
    installProductStructureOnUpdate,
    groups,
    products,
  }
}

export default productStructureStore()
