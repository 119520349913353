import { FREE_MOVEMENT_OF_PERSONS_COUNTRIES } from '@/config/constants'

export function hasSomePersonsNeedingPermit(persons) {
  return persons.some(person => {
    const isImmigrant = person?.immigrant ?? false
    return isImmigrant && !FREE_MOVEMENT_OF_PERSONS_COUNTRIES.includes(person.preInsurer.currentMovedToCHType)
  })
}

export function updatePersonInBasket(basket, personId, personData) {
  basket.persons.filter(p => p.personId === personId).map(p => Object.assign(p, personData))

  return basket
}
