import { computed, reactive, readonly, ref, watch } from 'vue'

import { logInfo } from '@/helpers/Logger'
import { getDistributionPartnerNumber } from '@/helpers/Partner'
import { ReactivityUtil } from '@/utils/Reactivity'

import partnerAPI from '@/api/partner'
import basketStore from '@/store/basket'

import { COLLECTIVE_REQUIRED_FLAGS, DISTRIBUTION_PARTNERS, SALES_PARTNER_TYPE } from '@/config/constants'

// GLOBAL DATA
const installed = ref(false)
const store = reactive({})
const partner = readonly(store)

/* Sales partner type intro:
 * If there is a collectiveContractConfig then it isCollective
 * If it is not collective then we can check for the salesPartnerType [PRIVATE_INSURER, BROKER, MOBILE_INSURER, PARTNER_DVP]
 * If non of the one before apply it is a common DVP sanitas customer
 */

function partnerStore() {
  // COMPUTED
  const advisorFullName = computed(() => {
    return `${partner.distributionPartnerInfo?.lastName ? partner.distributionPartnerInfo.lastName : ''} ${partner.distributionPartnerInfo?.firstName ? partner.distributionPartnerInfo.firstName : ''}`
  })

  const basketDistributionPartnerNumber = computed(() => {
    return getDistributionPartnerNumber(basketStore.basket.distributionPartners)
  })

  const collectivePersonnelNumberDescriptor = computed(() => {
    if (!partner.collectiveContractConfig?.personGroups) return undefined

    const { contractOwner, persons } = basketStore.basket

    let personGroup
    if (contractOwner?.personId) {
      personGroup = persons.find(p => p.personId === contractOwner.personId).collectivePersonGroup
    } else {
      personGroup = persons[0]?.collectivePersonGroup
    }
    const collectiveGroup = partner.collectiveContractConfig?.personGroups.find(g => g.groupNumber === personGroup)
    return collectiveGroup?.personnelNumberDescriptor
  })

  const isBroker = computed(() => {
    return !isCollective.value && partnerType.value === SALES_PARTNER_TYPE.BROKER
  })

  const isCollective = computed(() => {
    return !!partner.collectiveContractConfig
  })

  const isMobileDistributor = computed(() => {
    return !isCollective.value && partnerType.value === SALES_PARTNER_TYPE.MOBILE
  })

  const isPartner = computed(() => {
    return DISTRIBUTION_PARTNERS.includes(partnerType.value) && !basketStore.basket.existingCustomer
  })

  const isPersonnelNumberRequired = computed(() => {
    return COLLECTIVE_REQUIRED_FLAGS.includes(collectivePersonnelNumberDescriptor.value?.state)
  })

  const isPrivateInsurer = computed(() => {
    return !isCollective.value && partnerType.value === SALES_PARTNER_TYPE.PRIVATE_INSURER
  })

  const partnerType = computed(() => {
    return partner.distributionPartnerInfo?.salesPartnerType ?? false
  })

  // METHODS
  async function install(payload) {
    const data = await partnerAPI.getPartner(payload)

    logInfo(['%cPARTNER SET', 'color: green', data])

    ReactivityUtil.reAssign(store, data)
    installed.value = true
    return data
  }

  async function installOnUpdate(payload, originalBasket) {
    // if no basketId yet => abort
    // if basket submitted and partner already installed => abort
    if (!basketStore.basket.basketId || (basketStore.basket.submitted && installed.value)) return

    const collectiveChanged =
      originalBasket?.language !== basketStore.basket.language ||
      originalBasket.contractStartDate !== basketStore.basket.contractStartDate ||
      partner.collectiveContractConfig?.contractNumber !== basketStore.basket.collective?.contractNumber

    let partnerNumberChanged
    if (isCollective.value) {
      partnerNumberChanged =
        partner.collectiveContractConfig?.distributionPartnerSanitas !== basketDistributionPartnerNumber.value
    } else {
      partnerNumberChanged = payload.distributionPartnerNumber !== basketDistributionPartnerNumber.value
    }

    // already installed, abort
    if (installed.value && !collectiveChanged && !partnerNumberChanged) {
      // console.log('PARTNER ALREADY INSTALLED')
      return
    }

    // Check that all basket informatino are ready for partner loading
    const basketReady =
      basketStore.basket.contractStartDate && basketStore.basket.language && basketDistributionPartnerNumber.value
    if (!basketReady) {
      // console.log('PARTNER DELAYED AS BASKET NOT YET READY')
      return
    }

    return await install(payload)
  }

  function ready() {
    return new Promise(function (resolve) {
      if (installed.value) resolve()

      const unwatch = watch(
        () => installed.value,
        () => {
          unwatch()
          resolve()
        }
      )
    })
  }

  return {
    advisorFullName,
    basketDistributionPartnerNumber,
    collectivePersonnelNumberDescriptor,
    install,
    installed,
    installOnUpdate,
    isBroker,
    isCollective,
    isMobileDistributor,
    isPartner,
    isPersonnelNumberRequired,
    isPrivateInsurer,
    partner,
    ready,
  }
}

export default partnerStore()
