export default {
  mounted(el, binding) {
    el.__vueClickOutside__ = event => {
      const { handler, excluded } = binding.value

      // Check if the click or touch event was inside the element itself
      const isClickInside = el.contains(event.target)

      // Check if the click or touch event was on any of the excluded elements (including their children)
      const isClickOnExcludedElement = excluded.some(selector => {
        const excludedElements = document.querySelectorAll(selector)
        return Array.from(excludedElements).some(excludedElement => excludedElement.contains(event.target))
      })

      if ((!isClickInside && !isClickOnExcludedElement) || isClickOnExcludedElement) {
        handler(event)
      }
    }

    document.addEventListener('click', el.__vueClickOutside__, true)
    document.addEventListener('touchstart', el.__vueClickOutside__, true)
  },
  beforeUnmount(el) {
    document.removeEventListener('click', el.__vueClickOutside__, true)
    document.removeEventListener('touchstart', el.__vueClickOutside__, true)
    delete el.__vueClickOutside__
  },
}
