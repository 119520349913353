import { isRequired, MissingParameterError } from '@/utils/CustomErrors'

import useBackend from '@/hooks/useBackend'

import config from '@/config'

function partner() {
  // HOOKS
  const { doGet, setup } = useBackend()

  // INIT
  setup('/ovpv3/public', config.microserviceBaseUrl)

  async function getPartner(payload) {
    const response = await doGet({ url: `/collective-distribution-partner`, params: payload })
    return response.data
  }

  /**
   * getOrganizationName sends a GET-request to /distributionpartner/{distributionPartnerNumber}/organizationName
   * to get the data for a distribution partner's organziation
   * @param {string} distributionPartnerNumber
   * @param {object} params
   * @param {string} params.contractStartDate
   * @param {string} params.language
   * @return {Promise<object>}
   */
  async function getOrganizationName(distributionPartnerNumber = isRequired('distributionPartnerNumber'), params) {
    if (!params.contractStartDate) throw new MissingParameterError('contractStartDate')
    if (!params.language) throw new MissingParameterError('language')

    const response = await doGet({
      url: `/distributionpartner/${distributionPartnerNumber}/organizationName`,
      params: { ...params },
    })
    return response.data
  }

  return {
    getPartner,
    getOrganizationName,
  }
}

export default partner()
