import { computed, readonly } from 'vue'
import { cloneDeep, isNil } from 'lodash'

import { DateTimeHelper } from '@/helpers/DateTime'

import { ADULT, MINOR, SENIOR, GENDER } from '@/config/constants'

import basketStore from '@/store/basket'
import useContract from '@/hooks/useContract'
import useI18n from '@/hooks/useI18n'
import useProduct from '@/hooks/useProduct'

export default function usePersonDetails() {
  // HOOKS
  const { contractPersonsWithDetails, getCaveats } = useContract()
  const { t } = useI18n()
  const { getCategoryIdFromProduct, getSelectedProductsOfPerson } = useProduct()

  // COMPUTED
  const selectedProductCount = computed(() => {
    return personsWithDetails.value.reduce((acc, value) => {
      acc += value.selectedProducts.length
      return acc
    }, 0)
  })

  const basketTotal = computed(() => {
    const __total = personsWithDetails.value
      .reduce((total, person) => {
        total += person.total
        return total
      }, 0)
      .toFixed(2)

    return parseFloat(__total)
  })

  const personsDetails = computed(() => {
    const data = {}
    basketStore.basket.persons.forEach((person, index) => {
      const __number = index + 1
      const __selectedProducts = getSelectedProductsOfPerson(person.products.products)
      const __recommendations = person.recommendations.map(productId => {
        return Object.assign({}, person.products.products[productId], {
          productId,
          categoryId: getCategoryIdFromProduct(productId),
        })
      })
      const __age = DateTimeHelper.getAge(person.personData.dateOfBirth)

      let icon
      if (__age >= 18) {
        icon = person.personData.gender === GENDER.MALE ? 'man' : 'woman'
      } else if (__age < 18 && __age >= 5) {
        icon = person.personData.gender === GENDER.MALE ? 'boy' : 'girl'
      } else if (__age < 5 && __age >= 0) {
        icon = 'baby'
      } else {
        icon = 'unborn'
      }

      data[person.personId] = {
        number: index + 1,
        age: __age,
        icon,
        firstName: person.personData.firstName ?? t('person.person', { number: __number }),
        total: parseFloat(
          __selectedProducts
            .reduce((total, product) => {
              total += product.price.price
              return total
            }, 0)
            .toFixed(2)
        ),
        caveats: getCaveats(person),

        // @note don't use `products` or `recommendations` as a name, as it's already in use by data from backend
        selectedProducts: __selectedProducts,
        productRecommendations: __recommendations,
      }
    })

    return data
  })

  const personsWithDetails = computed(() => {
    const people = (basketStore.basket.persons || []).map(person => {
      return Object.assign({}, person, personsDetails.value[person.personId])
    })

    return readonly(people)
  })

  const signees = computed(() => {
    if (!basketStore.basket.persons) return []

    const __persons = cloneDeep(personsWithDetails.value)

    // According to business only persons with contractModified need to sign in all cases
    const personsToSign = __persons.filter(person => person.contractModified)
    // Head should always be the last signee
    const ownerIndex = personsToSign.findIndex(person => person.personId === basketStore.basket.contractOwner.personId)
    if (ownerIndex > -1) {
      personsToSign.push(personsToSign.splice(ownerIndex, 1)[0])
    }
    return personsToSign
  })

  // METHODS
  function getAgeBracketFromPerson(personId, seniorAge = 65) {
    const person = getPersonWithDetails(personId)

    if (isNil(person.age)) {
      return null
    } else if (person.age < 18) {
      return MINOR
    } else if (person.age >= seniorAge) {
      return SENIOR
    } else {
      return ADULT
    }
  }

  function getPersonsByMutability(__persons = personsWithDetails.value) {
    const data = { mutable: [], immutable: [] }
    __persons.forEach(person => {
      // As immutable persons are always existing customers, we need to get them from the contractPersonsWithDetails as only there we get the selectedProducts for each one
      // Edge case: getFamily call does not load contracts, so we need to take person instead for login
      const contractPerson = contractPersonsWithDetails.value.length
        ? contractPersonsWithDetails.value.find(__contractPerson => __contractPerson.personId === person.partnerNumber)
        : person
      person.contractModified ? data.mutable.push(person) : data.immutable.push(contractPerson)
    })

    return data
  }

  function getPersonWithDetails(personId) {
    return personsWithDetails.value.find(p => p.personId === personId)
  }

  return {
    basketTotal,
    personsDetails,
    personsWithDetails,
    selectedProductCount,
    signees,

    getAgeBracketFromPerson,
    getPersonsByMutability,
    getPersonWithDetails,
  }
}
