/**
 * Directive for embedding the unblue co-browsing masking and protection from HTML elements
 *
 * @see https://www.unblu.com/en/docs/latest/knowledge-base/embedded-co-browsing-masking-and-hiding-webpage-elements.html#protecting-content
 *
 * @examples
 * `<div v-unblu-captured >...`  ==> only text input childs will be captured (asterisks placeholder instead of the content)
 * `<div v-unblu-protected >...` ==> only the elements mark as protected (shows lock icon)
 * `<div v-unblu-blocked >...`   ==> both will be set automatically
 * `<div v-unblu-hidden >...`    ==> hide the element for the agent
 */
export default {
  install(app) {
    app.directive('unblu-captured', {
      mounted: capturing,
      updated: capturing,
      unmounted: el => capturing(el, false),
    })

    app.directive('unblu-protected', {
      mounted: protecting,
      updated: protecting,
      unmounted: el => protecting(el, false),
    })

    app.directive('unblu-blocked', {
      mounted: el => {
        capturing(el)
        protecting(el)
      },
      updated: el => {
        capturing(el)
        protecting(el)
      },
      unmounted: el => {
        capturing(el, false)
        protecting(el, false)
      },
    })

    app.directive('unblu-hidden', {
      mounted: hiding,
      updated: hiding,
      unmounted: el => hiding(el, false),
    })
  },
}

function capturing(el, addAttr = true) {
  const value = addAttr ? 'block' : false
  toggleAttribute(el, 'unbluCapturingHint', value)

  if (!['INPUT', 'TEXTAREA'].includes(el.tagName)) {
    const elements = Array.from(el.querySelectorAll('input, textarea, select'))
    elements.forEach(element => {
      toggleAttribute(element, 'unbluCapturingHint', value)
    })
  }
}

function protecting(el, addAttr = true) {
  const value = addAttr ? 'block' : false
  toggleAttribute(el, 'unbluEditingHint', value)
}

function hiding(el, addAttr = true) {
  const value = addAttr ? 'true' : false
  toggleAttribute(el, 'x-unblu-skip-element', value)
}

function toggleAttribute(el, key, value) {
  if (!el) {
    console.warn('this should not happen -> investigate')
    return
  }

  if (value) {
    el.setAttribute(key, value)
  } else {
    el.removeAttribute(key)
  }
}
