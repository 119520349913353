/**
 *  isProductReloadNecessary returns true if either a contract start date, a collective contract number or the language has changed
 *  @param {Basket} newBasket
 *  @param {Basket} oldBasket
 *  @returns {boolean}
 */
export function isProductReloadNecessary(newBasket, oldBasket) {
  return (
    newBasket?.collective?.contractNumber !== oldBasket.collective?.contractNumber ||
    hasContractStartDateChanged(newBasket, oldBasket) ||
    newBasket?.language !== oldBasket.language
  )
}

/**
 *  getAllContractStartDates returns a set with all contract startdates (kvg and vvg) for all persons
 *  @param {Basket} __basket
 *  @returns {}
 */
export function getAllContractStartDates(__basket) {
  const contractStartDates = new Set()
  if (__basket.persons) {
    __basket.persons.forEach(p => {
      contractStartDates.add(p.kvgContractStartDate)
      contractStartDates.add(p.vvgContractStartDate)
    })
  }
  return contractStartDates
}

/**
 *  hasContractStartDateChanged returns true if the provided baskets don't contain the same contract startdates
 *  @param {Basket} newBasket
 *  @param {Basket} oldBasket
 *  @returns {boolean}
 */
function hasContractStartDateChanged(newBasket, oldBasket) {
  const newContractStartDates = getAllContractStartDates(newBasket)
  const oldContractStartDates = getAllContractStartDates(oldBasket)

  if (newContractStartDates.size !== oldContractStartDates.size) {
    return true
  }

  for (const startDate of newContractStartDates) {
    if (!oldContractStartDates.has(startDate)) {
      return true
    }
  }
}
