<template>
  <!-- prettier-ignore -->
  <div
    class="section"
    :class="{
      [`section--${$props.theme}`]: true,
      'section--invalid': $props.invalid,
      'section--dap': $props.isDap,
    }"
  >
    <div v-if="$slots.title">
      <div class="lead-1">
        <slot name="title" />
      </div>
    </div>

    <div v-if="$slots.inactiveTitle">
      <div class="lead-1">
        <slot name="inactiveTitle" />
      </div>
    </div>

    <div
      v-if="$slots.content"
      class="section__content"
    >
      <slot name="content" />
    </div>

    <slot />
  </div>
</template>

<script setup>
// INIT
defineProps({
  invalid: {
    type: Boolean,
    default: false,
  },
  isDap: {
    type: Boolean,
    default: false,
  },
})
</script>

<style scoped>

</style>
