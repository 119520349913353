import {
  DEFAULT_DISTRIBUTION_PARTNER_NUMBER,
  DISTRIBUTION_PARTNER_TYPE,
  DISTRIBUTION_PARTNER_TYPES,
} from '@/config/constants'

/**
 * DISTRIBUTION_PARTNER_TYPE.ABSCHLUSS is the default used for e.g. Service center call
 * @param basket
 * @param type
 * @returns {*}
 */
export function getDistributionPartnerNumber(distributionPartners, type = DISTRIBUTION_PARTNER_TYPE.ABSCHLUSS) {
  const partner = distributionPartners?.find(p => p.distributionPartnerType === type)
  return partner?.distributionPartnerNumber
}

/**
 * Baskets created in dvp have the same distributionPartnerNumber for all types
 *
 * @param distributionPartnerNumber
 * @returns {{distributionPartnerNumber: *, distributionPartnerType: *}[]}
 */
export function createDistributionPartners(distributionPartnerNumber) {
  return DISTRIBUTION_PARTNER_TYPES.map(type => ({
    distributionPartnerType: type,
    distributionPartnerNumber,
  }))
}

// @TODO can be optimised by moving `basket.collective` to partnerStore and only use reduced params as needed
export function hasDistributionPartner(basket) {
  const partnerNr = getDistributionPartnerNumber(basket?.distributionPartners)
  const isDefaultDistribution = partnerNr === DEFAULT_DISTRIBUTION_PARTNER_NUMBER
  return !isDefaultDistribution && !basket.collective
}

// @TODO can be optimised by only use reduced params as needed
export function getPartnerNumberOfContractOwner(basket) {
  const contractOwnerId = basket?.contractOwner?.personId
  return basket.persons?.find(p => p.personId === contractOwnerId)?.partnerNumber
}
