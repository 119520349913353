// KVG group  types
export const GROUP_TYPE_MED = 'MED'
export const GROUP_TYPE_MULTI_ACCESS = 'MULTI_ACCESS'

// AVM definition types
export const AVM_TYPE_NET_MED = 'NETMED'
export const AVM_TYPE_CARE_MED = 'CAREMED'
export const AVM_TYPE_MED_BASE = 'MEDBASE'
export const AVM_TYPE_PATIENTS = 'PATIENTS'
export const AVM_TYPE_NAME = 'NAME'

const ACCEPTS_ALL = 'ACCEPTS_ALL'
const ACCEPTS_NOBODY = 'ACCEPTS_NOBODY'
const ACCEPTS_CHILDREN = 'ACCEPTS_CHILDREN'
const ACCEPTS_FAMILYMEMBERS = 'ACCEPTS_FAMILYMEMBERS'
const ACCORDING_FAMILY_DOCTOR = 'ACCORDING_FAMILY_DOCTOR'
const SPECIALIST_NOT_ELIGIBLE = 'SPECIALIST_NOT_ELIGIBLE'
const STATUS_UNKNOWN = 'STATUS_UNKNOWN'

export const medBaseAVMs = [AVM_TYPE_MED_BASE]
export const medAVMs = [AVM_TYPE_CARE_MED, AVM_TYPE_NET_MED]

export const medState = {
  ALL: ACCEPTS_ALL,
  NOBODY: ACCEPTS_NOBODY,
  CHILDREN: ACCEPTS_CHILDREN,
  FAMILYMEMBERS: ACCEPTS_FAMILYMEMBERS,
  ACCORDING_FAMILY_DOCTOR: ACCORDING_FAMILY_DOCTOR,
  SPECIALIST_NOT_ELIGIBLE: SPECIALIST_NOT_ELIGIBLE,
  UNKNOWN: STATUS_UNKNOWN,
}

export const MED_ICONS = {
  ACCEPTS_NOBODY: 'information',
  ACCEPTS_CHILDREN: 'only-children',
  ACCEPTS_FAMILYMEMBERS: 'family-members',
  SPECIALIST_NOT_ELIGIBLE: 'alert',
  STATUS_UNKNOWN: 'unknown',
}

export const MED_TYPES = {
  CARE_MED: AVM_TYPE_CARE_MED,
  MED_BASE: AVM_TYPE_MED_BASE,
  NET_MED: AVM_TYPE_NET_MED,
  PATIENTS: AVM_TYPE_PATIENTS,
  NAME: AVM_TYPE_NAME,
  MULTI_ACCESS: GROUP_TYPE_MULTI_ACCESS,
}

export const productAVMtypes = {
  ACSProdukt: AVM_TYPE_CARE_MED,
  ANETProdukt: AVM_TYPE_NET_MED,
  S2MUL1Produkt: AVM_TYPE_MED_BASE,
}

/**
 *
 * Google Maps CONSTANT
 *
 */
const ZOOM_MIN = 9
const ZOOM_MAX = 22
export const ZOOM_DEFAULT = 14

export const ZURICH_CENTER = {
  lat: 47.3769562,
  lng: 8.5225678,
}

export const MARKER_LIMIT = 100
export const MARKER_LIMIT_IE = 100

export const SWISS_BOUNDING_BOX = {
  min: {
    lat: 45.81,
    lng: 5.95,
  },
  max: {
    lat: 47.81,
    lng: 10.5,
  },
}

export const SANITAS_MARKER = {
  id: 'SANITAS',
  address: 'sanitas.postAddress',
  phoneNumber: 'sanitas.phoneNumber',
  minZoom: 16,
  geo: {
    latitude: 47.377423,
    longitude: 8.531953,
  },
}

export const googleIconStates = {
  NORMAL: 'NORMAL',
  HOVER: 'HOVER',
  ACTIVE: 'ACTIVE',
}

export const googleDefaultOptions = {
  mapTypeControl: false,
  streetViewControl: false,
  minZoom: ZOOM_MIN,
  maxZoom: ZOOM_MAX,
  rotateControl: false,
  fullscreenControl: false,
  clickableIcons: false,

  zoomControl: true,
  scaleControl: false,
  disableDefaultUi: false,
  gestureHandling: 'greedy',
  styles: [
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ],
}
