<template>
  <!-- prettier-ignore -->
  <button
    class="text-button"
    tabindex="0"
    :class="classes"
    type="button"
    @click="$emit('click')"
  >
    <div class="text-button__inner">
      <slot name="leading"></slot>
      <span
        v-if="$props.label"
        v-html="$props.label"
      />
      <slot name="trailing"></slot>
    </div>
    <div class="text-button__overlay" />
  </button>
</template>

<script setup>
import { computed } from 'vue'

// INIT
defineEmits(['click'])
const props = defineProps({
  label: {
    type: String,
    default: '',
  },

  size: {
    validator(value) {
      return ['sm', 'lg'].includes(value)
    },
    default: 'lg',
  },
})

// COMPUTED
const classes = computed(() => {
  return {
    [`text-button--${props.size}`]: true,
  }
})
</script>

<style>
:root,
.appearance-positive {
  --dvp-text-button-color-background: transparent;
  --dvp-text-button-color-border: transparent;
  --dvp-text-button-color-text: var(--Interaction-States-enabled-default);
  --dvp-text-button-color-background-hovered: transparent;
  --dvp-text-button-color-border-hovered: var(--Interaction-States-hovered-default-a);
  --dvp-text-button-color-text-hovered: var(--Interaction-States-hovered-default-a);
  --dvp-text-button-color-background-active: transparent;
  --dvp-text-button-color-border-active: var(--Interaction-States-pressed-default-a);
  --dvp-text-button-color-text-active: var(--Interaction-States-pressed-default-a);
  --dvp-text-button-color-background-disabled: var(--Interaction-States-disabled-default);
  --dvp-text-button-color-border-disabled: var(--Interaction-States-disabled-default);
  --dvp-text-button-color-text-disabled: var(--Interaction-States-disabled-default-label);
  --dvp-text-button-c-outline: var(--on-surface);
}

.appearance-negative {
  --dvp-text-button-color-background: transparent;
  --dvp-text-button-color-border: transparent;
  --dvp-text-button-color-text: var(--Interaction-States-enabled-inverse);
  --dvp-text-button-color-background-hovered: rgba(25, 25, 25, 0.3);
  --dvp-text-button-color-border-hovered: rgba(25, 25, 25, 0.3);
  --dvp-text-button-color-text-hovered: var(--Interaction-States-label-inverse-b);
  --dvp-text-button-color-background-active: rgba(25, 25, 25, 0.38);
  --dvp-text-button-color-border-active: rgba(25, 25, 25, 0.38);
  --dvp-text-button-color-text-active: var(--Interaction-States-label-inverse-c);
  --dvp-text-button-color-background-disabled: var(--Interaction-States-disabled-inverse);
  --dvp-text-button-color-border-disabled: var(--Interaction-States-disabled-inverse);
  --dvp-text-button-color-text-disabled: var(--Interaction-States-disabled-inverse-label);
  --dvp-text-button-c-outline: var(--on-inverse-surface-variant);
}

.appearance-extended {
  --dvp-text-button-color-background: transparent;
  --dvp-text-button-color-border: transparent;
  --dvp-text-button-color-text: var(--Interaction-States-enabled-extended);
  --dvp-text-button-color-background-hovered: transparent;
  --dvp-text-button-color-border-hovered: var(--Interaction-States-hovered-extended-a);
  --dvp-text-button-color-text-hovered: var(--Interaction-States-hovered-extended-a);
  --dvp-text-button-color-background-active: transparent;
  --dvp-text-button-color-border-active: var(--Interaction-States-pressed-extended-a);
  --dvp-text-button-color-text-active: var(--Interaction-States-pressed-extended-a);
  --dvp-text-button-color-background-disabled: var(--Interaction-States-disabled-extended);
  --dvp-text-button-color-border-disabled: var(--Interaction-States-disabled-extended);
  --dvp-text-button-color-text-disabled: var(--Interaction-States-disabled-extended-label);
  --dvp-text-button-c-outline: var(--on-surface);
}
</style>

<style scoped>
.text-button {
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  border: none;
  position: relative;
  font-weight: var(--font-weight-bold);
  color: var(--dvp-text-button-color-text);
  transition-property: color;
  transition-timing-function: ease-in;
  transition-duration: 0.15s;
  width: max-content;
  font-family: var(--font-family-open-sans);

  .text-button__overlay {
    background-color: var(--dvp-text-button-color-background);
    border-color: var(--dvp-text-button-color-border);
    transition-property: background-color, border-color;
    transition-timing-function: ease-in;
    transition-duration: 0.15s;
  }

  &:hover {
    color: var(--dvp-text-button-color-text-hovered);

    .text-button__overlay {
      background-color: var(--dvp-text-button-color-background-hovered);
      border-color: var(--dvp-text-button-color-border-hovered);
    }
  }

  &:active {
    color: var(--dvp-text-button-color-text-active);
    .text-button__overlay {
      background-color: var(--dvp-text-button-color-background-active);
      border-color: var(--dvp-text-button-color-border-active);
    }
  }

  &:focus-visible {
    .text-button__overlay {
      outline: 2px solid var(--dvp-text-button-c-outline);
      outline-offset: var(--fixed-spacing-fix-01);
    }
  }

  &[disabled] {
    color: var(--dvp-text-button-color-text-disabled);
    pointer-events: none;

    .text-button__inner span {
      text-decoration: line-through;
    }

    .text-button__overlay {
      background-color: var(--dvp-text-button-color-background-disabled);
      border-color: var(--dvp-text-button-color-border-disabled);
    }
  }
}

.text-button--sm {
  font-size: var(--font-size-bold-sm);
  letter-spacing: var(--letter-spacing-bold-sm);
  line-height: var(--line-height-bold-sm);

  & > .text-button__overlay {
    height: var(--fixed-spacing-fix-06);
  }
}

.text-button--lg {
  font-size: var(--font-size-bold-md);
  letter-spacing: var(--letter-spacing-bold-md);
  line-height: var(--line-height-bold-md);

  & > .text-button__overlay {
    height: var(--fixed-spacing-fix-08);
  }
}

.text-button__inner {
  position: relative;
  z-index: var(--dvp-stack-level-element);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--fixed-spacing-fix-02);
  white-space: nowrap;
}

.text-button__overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% + 2 * (var(--fixed-spacing-fix-02)));
  border: 1px solid transparent;
  border-radius: var(--fixed-border-radius-fix-full);
}
</style>
