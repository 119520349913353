<template>
  <a
    class="alink"
    tabindex="0"
    :href="$props.href"
    :target="$props.target"
    @click="$emit('click')"
    @keydown.enter="$emit('click')"
  >
    <div
      class="alink__content"
      :class="classes"
    >
      <slot name="leading"></slot>
      <span
        v-if="$props.label"
        v-text="$props.label"
      />
      <slot name="trailing"></slot>
    </div>
  </a>
</template>

<script setup>
import { computed } from 'vue'

// INIT
defineEmits(['click'])
const props = defineProps({
  href: {
    type: String,
    default: undefined,
  },

  label: {
    type: String,
    default: undefined,
  },

  occurence: {
    validator(value) {
      return ['standalone', 'inline'].includes(value)
    },
    default: 'standalone',
  },

  size: {
    validator(value) {
      return ['body-1', 'body-2', 'body-3'].includes(value)
    },
    default: 'body-2',
  },

  target: {
    type: String,
    default: '_self',
  },
})

// COMPUTED
const classes = computed(() => {
  return {
    [`alink__content--${props.occurence}`]: true,
    [`alink__content--${props.size}`]: true,
  }
})
</script>

<style>
:root,
.appearance-positive {
  --dvp-lnk-c: var(--Interaction-States-enabled-default);
  --dvp-lnk-c-hovered: var(--Interaction-States-hovered-default-a);
  --dvp-lnk-c-active: var(--Interaction-States-pressed-default-a);
  --dvp-lnk-c-disabled: var(--Interaction-States-disabled-default-label);

  --dvp-lnk-c-outline: var(--on-surface);
}

.appearance-negative {
  --dvp-lnk-c: var(--Interaction-States-enabled-inverse);
  --dvp-lnk-c-hovered: var(--Interaction-States-hovered-inverse-a);
  --dvp-lnk-c-active: var(--Interaction-States-pressed-inverse-a);
  --dvp-lnk-c-disabled: var(--Interaction-States-disabled-inverse-label);

  --dvp-lnk-c-outline: var(--on-inverse-surface-variant);
}

.appearance-extended {
  --dvp-lnk-c: var(--Interaction-States-enabled-extended);
  --dvp-lnk-c-hovered: var(--Interaction-States-hovered-extended-a);
  --dvp-lnk-c-active: var(--Interaction-States-pressed-extended-a);
  --dvp-lnk-c-disabled: var(--Interaction-States-disabled-extended-label);

  --dvp-lnk-c-outline: var(--on-surface);
}
</style>

<style scoped>
.alink {
  display: inline-block;

  &:hover .alink__content {
    color: var(--dvp-lnk-c-hovered);
    border-color: var(--dvp-lnk-c-hovered);
  }

  &:active .alink__content {
    color: var(--dvp-lnk-c-active);
    border-color: var(--dvp-lnk-c-active);
  }

  &[disabled='true'] {
    pointer-events: none;
    text-decoration: line-through;

    .alink__content {
      color: var(--dvp-lnk-c-disabled);
      border-color: var(--dvp-lnk-c-disabled);
    }
  }

  &:focus-visible .alink__content {
    outline: 2px solid var(--dvp-lnk-c-outline);
    outline-offset: var(--fixed-spacing-fix-01);
    border-radius: var(--fixed-border-radius-fix-02);
  }
}

.alink__content {
  display: flex;
  align-items: center;
  gap: var(--fixed-spacing-fix-02);
  transition-property: color, border-color;
  transition-duration: 150ms;
  transition-timing-function: ease-in;
  cursor: pointer;
  font-weight: var(--font-weight-bold);
  color: var(--dvp-lnk-c);
  border-color: var(--dvp-lnk-c);
  /* width: max-content; @note: prevents text breaking */

  &--inline {
    border-bottom: 1px solid;
  }

  &--body-1 {
    font-size: var(--font-size-bold-lg);
    letter-spacing: var(--letter-spacing-bold-lg);
    line-height: var(--line-height-bold-lg);
  }

  &--body-2 {
    font-size: var(--font-size-bold-md);
    letter-spacing: var(--letter-spacing-bold-md);
    line-height: var(--line-height-bold-md);
  }

  &--body-3 {
    font-size: var(--font-size-bold-sm);
    letter-spacing: var(--letter-spacing-bold-sm);
    line-height: var(--line-height-bold-sm);
  }
}

@media (--v-large) {
  .alink__content {
    &--body-1 {
      font-size: var(--font-size-bold-xl);
      letter-spacing: var(--letter-spacing-bold-xl);
    }
  }
}

@media (--v-wide) {
  .alink__content {
    &--body-1 {
      font-size: var(--font-size-bold-2xl);
      letter-spacing: var(--letter-spacing-bold-2xl);
    }
  }
}
</style>
