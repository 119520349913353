import config from '@/config'

import useBackend from '@/hooks/useBackend'

const types = {
  FIXED: ['FIXED_LINE_OR_MOBILE', 'FIXED_LINE', 'UAN'],
  MOBILE: ['MOBILE', 'FIXED_LINE_OR_MOBILE'],
}

function validations() {
  // HOOKS
  const { doGet, setup } = useBackend()

  // INIT
  setup('/validations', config.microserviceBaseUrl)

  // METHODS

  /**
   *  getPhoneInfo sends a GET-request to /validations/phone-number/phone-info to check whether
   *  the given phoneNumber is valid
   *  @see {@link https://validations.dev.gcp.sanet17.ch/swagger-ui/index.html#}
   *  @param {string} phoneNumber
   *  @returns Promise<{object}>
   */
  async function getPhoneInfo(phoneNumber) {
    return await doGet({ url: '/phone-number/phone-info', params: { phoneNumber } })
  }

  async function validatePhoneNumber(value, type = false) {
    if (!value) return true

    const response = await getPhoneInfo(value)

    const data = response?.data
    const valid = data?.valid
    const correctType = !type || types[type].includes(data?.type)

    if (valid && correctType) {
      return true
    } else if (valid && !correctType) {
      return false
    } else {
      return false
    }
  }

  return {
    getPhoneInfo,
    validatePhoneNumber,
  }
}

export default validations()
