import { logError } from '@/helpers/Logger'
import { SortUtil } from '@/utils/Sorting'

export default function useCriteria() {
  // input, currency = 'CHF', fixed = 2
  function formatCurrency({ input, currency = 'CHF', fixed = 2 }) {
    let formatted = ''
    const number = parseFloat(input)
    if (Number.isNaN(number)) {
      return formatted
    }
    let options = {
      minimumFractionDigits: fixed,
      maximumFractionDigits: fixed,
    }
    if (currency) {
      options = {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'code',
      }
    }
    try {
      formatted = new Intl.NumberFormat('de-CH', options).format(number)
    } catch (error) {
      logError(`Couldn't format currency: ${error}`)
    }
    return formatted
  }

  function getFranchiseValuesByKey(options, key = 'value') {
    const selectedOption = options.find(o => o.selected)

    return options.reduce((items, option) => {
      if (!items.includes(option[key])) {
        items.push(option[key])
      }
      return items
    }, []).map(item => {
      return {
        value: item,
        label: formatCurrency({ input: item, currency: null, fixed: 0 }),
        selected: selectedOption[key] === item
      }
    }).sort(SortUtil.sortReverseByParam('value'))
  }

  return {
    formatCurrency,
    getFranchiseValuesByKey
  }
}
